import React from 'react';
import { CheckCircle, XCircle, AlertCircle, Circle } from 'lucide-react';

const ControlPointIcon = ({ checkedIn, distance, closeTime }) => {
    if (!checkedIn) {
        return (
            <div className="text-red-500 hover:text-red-700 transition-colors" title="Not checked in">
                <XCircle className="h-6 w-6" />
            </div>
        );
    }

    const checkInTime = new Date(checkedIn.Timestamp);
    const controlCloseTime = new Date(closeTime);

    if (checkedIn.isAdminCheckIn) {
        return (
            <div className="text-blue-500 hover:text-blue-700 transition-colors" title="Admin check-in">
                <Circle className="h-6 w-6 fill-current" />
            </div>
        );
    }

    if (distance > 200 || checkInTime > controlCloseTime) {
        return (
            <div 
                className="text-yellow-500 hover:text-yellow-700 transition-colors"
                title={distance > 200 ? "Distance exceeded limit" : "Late check-in"}
            >
                <AlertCircle className="h-6 w-6" />
            </div>
        );
    }

    return (
        <div className="text-green-500 hover:text-green-700 transition-colors" title="Valid check-in">
            <CheckCircle className="h-6 w-6" />
        </div>
    );
};

export default ControlPointIcon;
