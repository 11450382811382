import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import AdminAccessCheck from './AdminAccessCheck';
import {
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  LayoutList,
  Eye,
  FileEdit,
  Cog,
  CircleDollarSign,
  Monitor,
  Flag,
  Navigation,
  FileText,
  CreditCard,
  FileWarning,
  AlertTriangle,
  CheckCircle2,
  Timer,
  MapPin,
  Ruler,
  BookOpen,
  Users,
  Tags,
  Tag,
  UserPlus
} from 'lucide-react';

/**
 * AdminLayout provides a collapsible sidebar for admin subpages.
 * We handle:
 *  - Sidebar collapse state
 *  - Dynamic categories based on race or live monitoring
 *  - Indicating active item based on the current location
 *  - Automatically scrolling the active item into view so that
 *    the sidebar does not jump to top on page navigation.
 *  - Show a top strip indicating the ride name for clarity
 */

const AdminLayout = ({ children, title }) => {
  const { rideId } = useParams();
  const location = useLocation(); // for checking active link
  const navItemRefs = useRef({});
  const [activeItemId, setActiveItemId] = useState(null);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState([
    'event-settings',
    'content-management',
    'route-management',
    'payment-settings'
  ]);

  const {
    data: rideData,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['rideDetails', rideId],
    queryFn: () => axios.get(`/rides/${rideId}`).then((res) => res.data),
    enabled: Boolean(rideId),
    staleTime: 1000 * 60 * 5,
  });

  const [isClubOwner, setIsClubOwner] = useState(true);
  const [isRaceEvent, setIsRaceEvent] = useState(false);

  useEffect(() => {
    if (!rideData || isLoading || isError) return;
    setIsClubOwner(rideData.user?.isClubOwner || false);
    setIsRaceEvent(rideData.timeControlType === 'race');
  }, [rideData, isLoading, isError]);

  useEffect(() => {
    // Expand either race-management or live-monitoring by default
    if (isRaceEvent) {
      setExpandedCategories((prev) => {
        if (!prev.includes('race-management')) {
          return [...prev, 'race-management'];
        }
        return prev;
      });
    } else {
      setExpandedCategories((prev) => {
        if (!prev.includes('live-monitoring')) {
          return [...prev, 'live-monitoring'];
        }
        return prev;
      });
    }
  }, [isRaceEvent]);

  // Toggle sidebar collapse
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  // Expand/collapse categories
  const toggleCategory = (catId) => {
    if (isSidebarCollapsed) {
      setIsSidebarCollapsed(false);
      setExpandedCategories([catId]);
    } else {
      if (expandedCategories.includes(catId)) {
        setExpandedCategories(expandedCategories.filter((id) => id !== catId));
      } else {
        setExpandedCategories([...expandedCategories, catId]);
      }
    }
  };

  // Build the Race or Live category
  const raceOrLiveCategory = isRaceEvent
    ? {
      id: 'race-management',
      label: 'Race Management',
      icon: <Flag className="h-5 w-5" />,
      items: [
        {
          id: 'register-rider',
          label: 'Register Rider',
          icon: <UserPlus className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/register-race`,
        },
        {
          id: 'race-results',
          label: 'Race Results',
          icon: <Timer className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/race-results`,
        },
        {
          id: 'race-categories',
          label: 'Race Categories',
          icon: <Tags className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/categories`,
        },
        {
          id: 'rider-categories',
          label: 'Rider Categories',
          icon: <Tag className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/rider-categories`,
        },
        {
          id: 'start-finish-lines',
          label: 'Start/Finish Lines',
          icon: <Flag className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/start-finish-lines`,
        },
      ],
    }
    : {
      id: 'live-monitoring',
      label: 'Live Monitoring',
      icon: <Monitor className="h-5 w-5" />,
      items: [
        {
          id: 'live-progress',
          label: 'Live Progress',
          icon: <Eye className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/live-riders`,
        },
        {
          id: 'missing-cp',
          label: 'Missing CP Monitor',
          icon: <AlertTriangle className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/missing-cp`,
        },
        {
          id: 'results-management',
          label: 'Results Management',
          icon: <CheckCircle2 className="h-4 w-4" />,
          link: `/rides/${rideId}/admin/riders`,
        },
      ],
    };

  // Event Settings
  const eventSettingsCategory = {
    id: 'event-settings',
    label: 'Event Settings',
    icon: <Cog className="h-5 w-5" />,
    items: [
      {
        id: 'basic-info',
        label: 'Basic Information',
        icon: <FileEdit className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/ride-information`,
      },
      {
        id: 'registration',
        label: 'Registration Details',
        icon: <Users className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/registration`,
      },
      {
        id: 'rules',
        label: 'Rules & Regulations',
        icon: <BookOpen className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/rules-and-regulations`,
      },
      {
        id: 'visibility',
        label: 'Event Visibility',
        icon: <Eye className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/visibility`,
      },
      {
        id: 'responsible-person',
        label: 'Responsible Person',
        icon: <Eye className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/responsible-person`,
      },
    ],
  };

  // Content Management
  const contentManagementCategory = {
    id: 'content-management',
    label: 'Content Management',
    icon: <FileText className="h-5 w-5" />,
    items: [
      {
        id: 'cover-image',
        label: 'Cover Image',
        icon: <FileEdit className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/cover-image`,
      },
      {
        id: 'event-description',
        label: 'Event Description',
        icon: <FileEdit className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/details-editor`,
      },
    ],
  };

  // Route Management
  const routeManagementCategory = {
    id: 'route-management',
    label: 'Route Management',
    icon: <Navigation className="h-5 w-5" />,
    items: [
      {
        id: 'route-description',
        label: 'Route Description',
        icon: <MapPin className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/route-description`,
      },
      {
        id: 'control-points',
        label: 'Control Points',
        icon: <Ruler className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/control-points`,
      },
      {
        id: 'time-settings',
        label: 'Timing Settings',
        icon: <Timer className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/ride-payment`,
      },
    ],
  };

  // Payment Settings
  const paymentSettingsCategory = {
    id: 'payment-settings',
    label: 'Payment Settings',
    icon: <CircleDollarSign className="h-5 w-5" />,
    items: [
      {
        id: 'payment-config',
        label: 'Payment Configuration',
        icon: <CreditCard className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/ride-payment`,
      },
      {
        id: 'offline-payments',
        label: 'Offline Payments',
        icon: <FileWarning className="h-4 w-4" />,
        link: `/rides/${rideId}/admin/offline-payments`,
      },
    ],
  };

  const navCategories = [
    raceOrLiveCategory,
    eventSettingsCategory,
    contentManagementCategory,
    routeManagementCategory,
    paymentSettingsCategory,
  ];

  // detect and highlight current route
  const isLinkActive = (itemLink) => {
    if (!itemLink || !location.pathname) return false;
    return location.pathname.startsWith(itemLink);
  };

  useEffect(() => {
    if (activeItemId && navItemRefs.current[activeItemId]) {
      navItemRefs.current[activeItemId].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [activeItemId, location.pathname]);

  const renderNavItem = (item) => {
    const active = isLinkActive(item.link);
    if (active && item.id !== activeItemId) {
      setActiveItemId(item.id);
    }

    return (
      <Link
        key={item.id}
        to={item.link}
        ref={(el) => (navItemRefs.current[item.id] = el)}
        className={`flex items-center gap-2 px-4 py-2 text-sm rounded-md transition-all
          ${active ? 'bg-blue-100 text-blue-700 font-semibold' : 'text-gray-700 hover:bg-blue-50'}
        `}
      >
        {item.icon}
        {!isSidebarCollapsed && (
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
            {item.label}
          </span>
        )}
      </Link>
    );
  };

  const renderCategory = (cat) => {
    const isExpanded = expandedCategories.includes(cat.id);
    return (
      <div key={cat.id} className="flex flex-col">
        <button
          onClick={() => toggleCategory(cat.id)}
          className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md transition-all"
        >
          <div className="flex items-center gap-2">
            {cat.icon}
            {!isSidebarCollapsed && (
              <span className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                {cat.label}
              </span>
            )}
          </div>
          {!isSidebarCollapsed && (
            <div className="text-gray-400">
              {isExpanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
            </div>
          )}
        </button>
        {isExpanded && !isSidebarCollapsed && (
          <div className="pl-6 flex flex-col space-y-1">
            {cat.items.map(renderNavItem)}
          </div>
        )}
      </div>
    );
  };

  return (
    <AdminAccessCheck isAdmin={isClubOwner}>
      <div className="flex min-h-screen w-full bg-gray-100 text-gray-800">
        {/* Sidebar */}
        <div
          className={`
            flex flex-col bg-white border-r border-gray-200
            transition-all duration-300
            ${isSidebarCollapsed ? 'w-16' : 'w-64'}
          `}
        >
          {/* Sidebar header / toggle button */}
          <div className="flex items-center justify-between h-16 px-4 bg-gray-50 border-b border-gray-200">
            {!isSidebarCollapsed && (
              <div className="flex flex-col">
                <div className="flex items-center gap-2 whitespace-nowrap">
                  <LayoutList className="h-5 w-5 text-blue-600" />
                  <span className="font-bold text-gray-600 overflow-hidden text-ellipsis">
                    Admin Menu
                  </span>
                </div>
                {/* Show ride name if available */}
                {!isLoading && !isError && rideData && (
                  <div className="text-xs text-gray-500 mt-1 truncate">
                    Editing: {rideData.name || 'No Name'}
                  </div>
                )}
              </div>
            )}
            <button
              onClick={toggleSidebar}
              className="text-gray-500 hover:text-gray-700"
              aria-label="Toggle sidebar"
            >
              {isSidebarCollapsed ? (
                <ChevronRight className="h-5 w-5" />
              ) : (
                <ChevronLeft className="h-5 w-5" />
              )}
            </button>
          </div>

          {/* Nav items */}
          <nav className="flex-1 p-2 space-y-2">
            {/* Only show categories if we have rideId and no loading error */}
            {rideId && !isError && !isLoading && navCategories.map(renderCategory)}
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6 space-y-4">
          {title && <h1 className="text-2xl font-bold mb-6">{title}</h1>}
          {children}
        </div>
      </div>
    </AdminAccessCheck>
  );
};

export default AdminLayout;