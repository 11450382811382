import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';

const ForRiders = () => {
  return (
    <div
      data-aos="fade-up"
      className="flex flex-col md:flex-row items-center my-12 p-8 bg-gradient-to-br from-purple-50 via-indigo-50 to-white shadow-lg rounded-lg backdrop-blur-md transition-transform transform hover:scale-[1.02] mx-4 md:mx-8"
    >
      <div className="md:w-1/2 p-4 order-last md:order-first">
        <img
          src="/rider.jpg"
          alt="Rider Showing Digital Certificate"
          className="w-full rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
        />
      </div>
      <div className="md:w-1/2 p-4">
        <h2 className="text-4xl font-extrabold mb-4 bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
          Celebrate Your Cycling Achievements
        </h2>
        <p className="text-gray-700 mb-6">
          With UltraRides, every ride is an opportunity to shine. Complete your rides, meet the control requirements, and receive permanent homologations. Showcase your cycling journey with digital certificates and a public profile page.
        </p>
        <ul className="mb-6 space-y-3">
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Permanent Homologations: Your homologation remains with you forever.</span>
          </li>
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Digital Certificates: Receive a digital certificate for every completed ride.</span>
          </li>
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Public Profile Page: Display all your homologations and share your achievements.</span>
          </li>
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Event Participation: Easily join and participate in events hosted by clubs.</span>
          </li>
        </ul>
        <a
          href="/create-account"
          className="inline-flex items-center px-8 py-3 rounded-full font-semibold text-xl text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 transform hover:-translate-y-1 transition-all shadow-lg hover:shadow-xl"
        >
          Join as a Rider
        </a>
      </div>
    </div>
  );
};

export default ForRiders;