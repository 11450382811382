import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../config/axiosConfig';
import { useAuth } from '../context/AuthContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RideHeader from './RideDetails/RideHeader';
import RideDetailsTab from './RideDetails/RideDetailsTab';
import ControlPointsTab from './RideDetails/ControlPointsTab';
import RouteTab from './RideDetails/RouteTab';
import ErrorMessage from './Message';
import WaiverModal from './RideDetails/WaiverModal';
import CheckIn from './RideDetails/CheckIn';
import ChatTab from './RideDetails/ChatTab';
import RegisteredRidersTab from './RideDetails/RegisteredRidersTab';
import { Info, Clock } from 'lucide-react';

const fetchRideDetails = async (rideId) => {
    const response = await axios.get(`/rides/${rideId}`);
    if (response.data.timeControlType.toLowerCase() === "race") {
        const categoryResults = response.data.registeredRiders
            .filter(rider => rider.rank && rider.rank <= 3)
            .map(rider => ({
                ...rider,
                categoryName: response.data.categories.find(c => c.id === rider.categoryId)?.name || 'Unknown Category'
            }))
            .sort((a, b) => a.rank - b.rank);
        response.data.categoryResults = categoryResults;
    }
    return response.data;
};

function RideDetails() {
    const { rideId } = useParams();
    const { state } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isWaiverModalOpen, setIsWaiverModalOpen] = useState(false);

    const { data: ride, error, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => fetchRideDetails(rideId),
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
    });

    useEffect(() => {
        if (ride?.name) {
            document.title = `${ride.name} | Ultra Rides`;
        }
    }, [ride?.name]);

    useEffect(() => {
        const tabNames = ["details", "time-stations", "route", "riders", "check-in", "chat"];
        const currentTab = location.hash.replace("#", "") || "details";
        setCurrentTabIndex(tabNames.findIndex(tab => currentTab.startsWith(tab)) || 0);
    }, [location]);

    const handleSave = async ({ field, value }) => {
        try {
            await axios.patch(`/rides/${ride?.rideId}`, { [field]: value, clubId: ride?.clubId });
            queryClient.invalidateQueries(['rideDetails', rideId]);
        } catch (error) {
            console.error(`Error updating ${field}:`, error);
            throw new Error("Cannot update ride details after registration closing date");
        }
    };

    const handleWaiverSubmit = () => {
        queryClient.invalidateQueries(['rideDetails', rideId]);
        setIsWaiverModalOpen(false);
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <ErrorMessage message="Error fetching ride details." />;

    const isUserRegistered = ride?.user?.status === 'Joined';
    let isClubOwner = ride?.user?.isClubOwner;
    if (state?.idTokenPayload?.email === 'rohanshar@gmail.com') {
        isClubOwner = true;
    }

    const tabNames = [
        "details",
        "time-stations",
        "route",
        "riders",
        "check-in",
        "chat",
    ];

    const registeredRidersTabName = `Riders (${ride?.registeredRiders?.length || 0})`;

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg mt-8 space-y-6">
            <RideHeader
                ride={ride}
                onSave={handleSave}
                isClubOwner={isClubOwner}
                state={state}
                isUserRegistered={isUserRegistered}
                rideId={rideId}
                queryClient={queryClient}
                navigate={navigate}
                onWaiverClick={() => setIsWaiverModalOpen(true)}
            />

            <Tabs
                selectedIndex={currentTabIndex}
                onSelect={index => navigate(`#${tabNames[index]}`)}
                className="bg-white rounded-xl shadow-sm p-4"
            >
                <TabList className="flex flex-wrap border-b border-gray-200">
                    <div className="flex items-center space-x-1 sm:space-x-2">
                        <Info className="w-4 h-4 text-gray-400" />
                        <span className="text-sm text-gray-600 hidden sm:inline">Ride Info:</span>
                    </div>
                    <Tab className="px-3 py-2 text-sm font-medium text-gray-600 hover:text-primary cursor-pointer border-b-2 border-transparent hover:border-primary -mb-[2px] outline-none transition-colors mr-4">Details</Tab>
                    <Tab className="px-3 py-2 text-sm font-medium text-gray-600 hover:text-primary cursor-pointer border-b-2 border-transparent hover:border-primary -mb-[2px] outline-none transition-colors mr-4">Time Stations</Tab>
                    <Tab className="px-3 py-2 text-sm font-medium text-gray-600 hover:text-primary cursor-pointer border-b-2 border-transparent hover:border-primary -mb-[2px] outline-none transition-colors mr-4">Route</Tab>
                    <Tab className="px-3 py-2 text-sm font-medium text-gray-600 hover:text-primary cursor-pointer border-b-2 border-transparent hover:border-primary -mb-[2px] outline-none transition-colors">
                        {registeredRidersTabName}
                    </Tab>
                    <div className="flex-grow"></div>
                    <div className="flex items-center space-x-1 sm:space-x-2 ml-4">
                        <Clock className="w-4 h-4 text-gray-400" />
                        <span className="text-sm text-gray-600 hidden sm:inline">During Ride:</span>
                    </div>
                    <Tab className="px-3 py-2 text-sm font-medium text-gray-600 hover:text-primary cursor-pointer border-b-2 border-transparent hover:border-primary -mb-[2px] outline-none transition-colors ml-2">Check-In</Tab>
                    <Tab className="px-3 py-2 text-sm font-medium text-gray-600 hover:text-primary cursor-pointer border-b-2 border-transparent hover:border-primary -mb-[2px] outline-none transition-colors">Chat</Tab>
                </TabList>

                <TabPanel>
                    <RideDetailsTab
                        ride={ride}
                        isClubOwner={isClubOwner}
                        onSave={handleSave}
                    />
                </TabPanel>
                <TabPanel>
                    <ControlPointsTab controlPoints={ride?.controlPoints || []} />
                </TabPanel>
                <TabPanel>
                    <RouteTab route={ride?.route} routeDetails={ride?.routeDetails} />
                </TabPanel>
                <TabPanel>
                    <RegisteredRidersTab
                        registeredRiders={ride?.registeredRiders || []}
                        isClubOwner={isClubOwner}
                        payment={ride?.payment}
                        categories={ride?.categories}
                        defaultCategoryId={ride?.defaultCategoryId}
                        timeControlType={ride?.timeControlType}
                    />
                </TabPanel>
                <TabPanel>
                    {isUserRegistered ? (
                        <CheckIn ride={ride} controlPoints={ride?.controlPoints || []} waiverSubmitted={ride?.user?.waiver || false} />
                    ) : (
                        <div className="p-4 text-center text-gray-600">Please register to see check-in details.</div>
                    )}
                </TabPanel>
                <TabPanel>
                    <ChatTab rideId={rideId} rideName={ride?.name} />
                </TabPanel>
            </Tabs>

            <WaiverModal
                isOpen={isWaiverModalOpen}
                onClose={() => setIsWaiverModalOpen(false)}
                onSubmit={handleWaiverSubmit}
                user={ride?.user || {}}
            />
        </div>
    );
}

export default RideDetails;
