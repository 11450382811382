import React from "react";
import axios from "../../config/axiosConfig";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

/**
 * Fetch the list of attendees for an event.
 * We assume the new API endpoint: GET /events/:eventId/attendees
 * returns data in the shape:
 * {
 *   attendees: [
 *     { urn: "R12345", name: "Alice Rider", status: "INTERESTED" },
 *     { urn: "R23456", name: "Bob Cyclist", status: "GOING" },
 *     ...
 *   ]
 * }
 */
async function fetchEventAttendees(eventId, token) {
  const response = await axios.get(`/events/${eventId}/attendees`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data; // e.g. { attendees: [...] }
}

function AttendeesList({ eventId }) {
  const { state } = useAuth();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["eventAttendees", eventId],
    queryFn: () => fetchEventAttendees(eventId, state.idToken),
    enabled: !!eventId && state.isAuthenticated,
    staleTime: 1000 * 60 * 2, // 2 minutes
  });

  if (!state.isAuthenticated) {
    // If not authenticated, don't show anything (or prompt login).
    return null;
  }

  if (isLoading) {
    return (
      <div className="bg-white p-4 rounded-md shadow mt-6">
        <p className="text-gray-600">Loading attendees...</p>
      </div>
    );
  }

  if (isError) {
    const errorMessage = error?.response
      ? `Error (${error.response.status}): ${
          error.response.data?.message || JSON.stringify(error.response.data)
        }`
      : `Error: ${error?.message || "Unable to fetch attendees"}`;

    return (
      <div className="bg-white p-4 rounded-md shadow mt-6">
        <p className="text-red-600">{errorMessage}</p>
      </div>
    );
  }

  const { attendees = [] } = data || {};
  if (attendees.length === 0) {
    return (
      <div className="bg-white p-4 rounded-md shadow mt-6">
        <p className="text-gray-600">No attendees yet.</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-4 rounded-md shadow mt-6">
      <h3 className="text-xl font-semibold text-gray-800 mb-3">
        Attendees ({attendees.length})
      </h3>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-primary text-white">
            <tr>
              <th className="py-3 px-4 text-left w-12">#</th>
              <th className="py-3 px-4 text-left">Profile</th>
              <th className="py-3 px-4 text-left">Name</th>
              <th className="py-3 px-4 text-left">URN</th>
              <th className="py-3 px-4 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {attendees.map((user, index) => (
              <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                <td className="py-3 px-4">
                  {index + 1}
                </td>
                <td className="py-3 px-4">
                  <img
                    src={`https://ultrarides-images.s3.ap-south-1.amazonaws.com/profiles/${user.urn}.jpg`}
                    alt={`Profile of ${user.name}`}
                    className="w-12 h-12 rounded-full object-cover shadow-sm"
                    onError={(e) => {
                      e.target.src = "/api/placeholder/48/48";
                    }}
                  />
                </td>
                <td className="py-3 px-4">
                  <Link
                    to={`/athletes/${user.urn}/profile`}
                    className="text-accent hover:underline"
                  >
                    {user.name}
                  </Link>
                </td>
                <td className="py-3 px-4">{user.urn}</td>
                <td className="py-3 px-4">
                  {user.status || "UNKNOWN"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AttendeesList;