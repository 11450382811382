/**
 * A more detailed list of Indian states/UTs and their major cities.
 * This is just an example subset. For a real app, expand or fetch from an API.
 */
export const statesAndCities = {
  "Andhra Pradesh": [
    "Visakhapatnam", "Vijayawada", "Guntur", "Nellore", "Kurnool",
    "Rajahmundry", "Tirupati", "Kakinada", "Kadapa", "Anantapur",
    "Eluru", "Ongole", "Vizianagaram", "Tenali", "Proddatur",
    "Chittoor", "Machilipatnam", "Adoni", "Hindupur", "Bhimavaram"
  ],

  // Union Territories
  "Andaman and Nicobar Islands": [
    "Port Blair", "Mayabunder", "Rangat", "Diglipur", "Little Andaman",
    "Car Nicobar", "Kamorta", "Campbell Bay", "Havelock Island", "Neil Island"
  ],
  "Chandigarh": [
    "Chandigarh", "Manimajra", "Burail", "Attawa", "Badheri",
    "Buterla", "Daria", "Dhanas", "Hallomajra", "Kaimbwala"
  ],
  "Dadra and Nagar Haveli and Daman and Diu": [
    "Daman", "Silvassa", "Diu", "Amli", "Samarvarni",
    "Dadra", "Naroli", "Vapi", "Dunetha", "Dabhel"
  ],
  "Delhi": [
    "New Delhi", "Delhi Cantonment", "Najafgarh", "Dwarka", "Rohini",
    "Pitampura", "Vikaspuri", "Janakpuri", "Vasant Kunj", "Saket",
    "Laxmi Nagar", "Karol Bagh", "Chandni Chowk", "Connaught Place", "Paharganj"
  ],
  "Jammu and Kashmir": [
    "Srinagar", "Jammu", "Anantnag", "Baramulla", "Udhampur",
    "Kathua", "Sopore", "Kupwara", "Pulwama", "Poonch",
    "Rajouri", "Handwara", "Bandipora", "Ganderbal", "Budgam"
  ],
  "Ladakh": [
    "Leh", "Kargil", "Diskit", "Thikse", "Khaltse",
    "Skurbuchan", "Zanskar", "Nubra", "Drass", "Nyoma"
  ],
  "Lakshadweep": [
    "Kavaratti", "Agatti", "Amini", "Andrott", "Minicoy",
    "Kalpeni", "Kiltan", "Chetlat", "Kadmat", "Bitra"
  ],
  "Puducherry": [
    "Pondicherry", "Karaikal", "Yanam", "Mahe", "Ozhukarai",
    "Villianur", "Ariyankuppam", "Thirubhuvanai", "Bahour", "Mannadipet"
  ],
  "Arunachal Pradesh": [
    "Itanagar", "Tawang", "Pasighat", "Ziro", "Naharlagun",
    "Roing", "Tezu", "Along", "Bomdila", "Changlang",
    "Daporijo", "Seppa", "Khonsa", "Anini", "Yingkiong"
  ],
  "Assam": [
    "Guwahati", "Silchar", "Dibrugarh", "Jorhat", "Nagaon",
    "Tinsukia", "Tezpur", "Karimganj", "Sivasagar", "Diphu",
    "Goalpara", "North Lakhimpur", "Dhubri", "Bongaigaon", "Barpeta",
    "Hojai", "Golaghat", "Mangaldoi", "Nalbari", "Haflong"
  ],
  "Bihar": [
    "Patna", "Gaya", "Bhagalpur", "Muzaffarpur", "Purnia",
    "Darbhanga", "Bihar Sharif", "Arrah", "Begusarai", "Katihar",
    "Munger", "Chhapra", "Danapur", "Saharsa", "Sasaram",
    "Hajipur", "Dehri", "Siwan", "Motihari", "Nawada",
    "Bagaha", "Bettiah", "Kishanganj", "Jamui", "Jehanabad"
  ],
  "Chhattisgarh": [
    "Raipur", "Bhilai", "Bilaspur", "Korba", "Rajnandgaon",
    "Raigarh", "Durg", "Jagdalpur", "Ambikapur", "Mahasamund",
    "Dhamtari", "Chirmiri", "Bhatapara", "Kanker", "Dongargarh",
    "Kondagaon", "Naila Janjgir", "Kawardha", "Koriya", "Takhatpur"
  ],
  "Goa": [
    "Panaji", "Vasco da Gama", "Margao", "Mapusa", "Ponda",
    "Bicholim", "Curchorem", "Cuncolim", "Sanguem", "Quepem",
    "Pernem", "Valpoi", "Sanquelim", "Canacona", "Mormugao"
  ],
  "Gujarat": [
    "Ahmedabad", "Surat", "Vadodara", "Rajkot", "Bhavnagar",
    "Jamnagar", "Junagadh", "Gandhinagar", "Gandhidham", "Anand",
    "Navsari", "Morbi", "Nadiad", "Surendranagar", "Bharuch",
    "Mehsana", "Bhuj", "Porbandar", "Palanpur", "Valsad",
    "Vapi", "Gondal", "Veraval", "Godhra", "Patan"
  ],
  "Haryana": [
    "Gurugram", "Faridabad", "Panipat", "Ambala", "Yamunanagar",
    "Rohtak", "Hisar", "Karnal", "Sonipat", "Panchkula",
    "Bhiwani", "Sirsa", "Bahadurgarh", "Jind", "Thanesar",
    "Kaithal", "Rewari", "Palwal", "Fatehabad", "Charkhi Dadri"
  ],
  "Himachal Pradesh": [
    "Shimla", "Dharamshala", "Mandi", "Kullu", "Solan",
    "Palampur", "Sundernagar", "Bilaspur", "Hamirpur", "Una",
    "Nahan", "Kangra", "Chamba", "Dalhousie", "Baddi",
    "Nurpur", "Kinnaur", "Manali", "Kasauli", "Mcleodganj"
  ],
  "Jharkhand": [
    "Ranchi", "Jamshedpur", "Dhanbad", "Bokaro", "Deoghar",
    "Hazaribagh", "Giridih", "Ramgarh", "Phusro", "Medininagar",
    "Chirkunda", "Gumla", "Dumka", "Chakradharpur", "Purbi Singhbhum",
    "Chaibasa", "Lohardaga", "Pakur", "Godda", "Jamtara"
  ],
  "Karnataka": [
    "Bengaluru", "Mysuru", "Mangaluru", "Hubballi", "Belagavi",
    "Ballari", "Vijayapura", "Kalaburagi", "Davanagere", "Tumakuru",
    "Shivamogga", "Raichur", "Bidar", "Hassan", "Gadag",
    "Udupi", "Robertsonpet", "Bhadravati", "Chitradurga", "Hospet",
    "Kolar", "Mandya", "Chikkamagaluru", "Bagalkot", "Madikeri"
  ],
  "Kerala": [
    "Thiruvananthapuram", "Kochi", "Kozhikode", "Kollam", "Thrissur",
    "Palakkad", "Alappuzha", "Kannur", "Kottayam", "Malappuram",
    "Kasaragod", "Pathanamthitta", "Thalassery", "Kayamkulam", "Manjeri",
    "Ponnani", "Vatakara", "Kanhangad", "Taliparamba", "Koyilandy"
  ],
  "Madhya Pradesh": [
    "Bhopal", "Indore", "Gwalior", "Jabalpur", "Ujjain",
    "Sagar", "Dewas", "Satna", "Ratlam", "Rewa",
    "Murwara", "Singrauli", "Burhanpur", "Khandwa", "Morena",
    "Bhind", "Chhindwara", "Guna", "Shivpuri", "Vidisha",
    "Chhatarpur", "Damoh", "Mandsaur", "Khargone", "Neemuch"
  ],
  "Maharashtra": [
    "Mumbai", "Pune", "Nagpur", "Nashik", "Thane",
    "Aurangabad", "Solapur", "Kalyan-Dombivli", "Vasai-Virar", "Bhiwandi",
    "Amravati", "Nanded", "Kolhapur", "Sangli", "Malegaon",
    "Jalgaon", "Akola", "Latur", "Dhule", "Ahmednagar",
    "Chandrapur", "Parbhani", "Ichalkaranji", "Jalna", "Ambernath"
  ],
  "Manipur": [
    "Imphal", "Bishnupur", "Thoubal", "Kakching", "Ukhrul",
    "Churachandpur", "Senapati", "Jiribam", "Moreh", "Lilong",
    "Mayang Imphal", "Kangpokpi", "Yairipok", "Sugnu", "Moirang"
  ],
  "Meghalaya": [
    "Shillong", "Tura", "Jowai", "Nongstoin", "Williamnagar",
    "Baghmara", "Resubelpara", "Mankachar", "Nongpoh", "Mairang",
    "Cherrapunji", "Mawlai", "Lawsohtun", "Madanrting", "Nongthymmai"
  ],
  "Mizoram": [
    "Aizawl", "Lunglei", "Champhai", "Serchhip", "Kolasib",
    "Saitual", "Lawngtlai", "Khawzawl", "Hnahthial", "Mamit",
    "Saiha", "Zawlnuam", "Darlawn", "Khawhai", "Bairabi"
  ],
  "Nagaland": [
    "Kohima", "Dimapur", "Mokokchung", "Tuensang", "Wokha",
    "Mon", "Zunheboto", "Phek", "Kiphire", "Longleng",
    "Peren", "Noklak", "Tseminyu", "Chumukedima", "Meluri"
  ],
  "Odisha": [
    "Bhubaneswar", "Cuttack", "Rourkela", "Sambalpur", "Brahmapur",
    "Puri", "Balasore", "Bhadrak", "Baripada", "Jharsuguda",
    "Jeypore", "Barbil", "Dhenkanal", "Balangir", "Paradeep",
    "Rayagada", "Angul", "Kendujhar", "Sunabeda", "Paradip"
  ],
  "Punjab": [
    "Ludhiana", "Amritsar", "Jalandhar", "Patiala", "Bathinda",
    "Mohali", "Hoshiarpur", "Batala", "Pathankot", "Moga",
    "Abohar", "Malerkotla", "Khanna", "Muktsar", "Barnala",
    "Rajpura", "Firozpur", "Kapurthala", "Fazilka", "Faridkot"
  ],
  "Rajasthan": [
    "Jaipur", "Udaipur", "Jodhpur", "Kota", "Bikaner",
    "Ajmer", "Bhilwara", "Alwar", "Bharatpur", "Sikar",
    "Sri Ganganagar", "Pali", "Tonk", "Kishangarh", "Beawar",
    "Hanumangarh", "Dhaulpur", "Gangapur", "Sawai Madhopur", "Churu",
    "Jhunjhunu", "Banswara", "Nagaur", "Bundi", "Sujangarh"
  ],
  "Sikkim": [
    "Gangtok", "Namchi", "Gyalshing", "Mangan", "Singtam",
    "Jorethang", "Rangpo", "Rongli", "Soreng", "Nayabazar",
    "Ravangla", "Rhenock", "Yuksom", "Lachung", "Lachen"
  ],
  "Tamil Nadu": [
    "Chennai", "Coimbatore", "Madurai", "Tiruchirappalli", "Salem",
    "Tirunelveli", "Tiruppur", "Thanjavur", "Vellore", "Erode",
    "Thoothukkudi", "Dindigul", "Karur", "Nagercoil", "Hosur",
    "Kumbakonam", "Kanchipuram", "Cuddalore", "Tiruvannamalai", "Pollachi",
    "Pudukkottai", "Neyveli", "Nagapattinam", "Viluppuram", "Ramanathapuram"
  ],
  "Telangana": [
    "Hyderabad", "Warangal", "Nizamabad", "Karimnagar", "Khammam",
    "Ramagundam", "Mahbubnagar", "Nalgonda", "Adilabad", "Suryapet",
    "Siddipet", "Miryalaguda", "Jagityal", "Mancherial", "Bhongir",
    "Bodhan", "Kamareddy", "Nirmal", "Sangareddy", "Tandur"
  ],
  "Tripura": [
    "Agartala", "Udaipur", "Dharmanagar", "Belonia", "Kailasahar",
    "Ambassa", "Khowai", "Teliamura", "Melaghar", "Mohanpur",
    "Ranirbazar", "Sabroom", "Amarpur", "Kamalpur", "Bishalgarh"
  ],
  "Uttarakhand": [
    "Dehradun", "Haridwar", "Nainital", "Roorkee", "Haldwani",
    "Rudrapur", "Kashipur", "Rishikesh", "Pithoragarh", "Ramnagar",
    "Mussoorie", "Srinagar", "Kathgodam", "Jaspur", "Almora",
    "Tehri", "Pauri", "Kotdwar", "Chamoli", "Uttarkashi"
  ],
  "Uttar Pradesh": [
    "Lucknow", "Kanpur", "Ghaziabad", "Agra", "Varanasi",
    "Meerut", "Prayagraj", "Bareilly", "Aligarh", "Moradabad",
    "Saharanpur", "Gorakhpur", "Noida", "Firozabad", "Loni",
    "Jhansi", "Muzaffarnagar", "Mathura", "Shahjahanpur", "Rampur",
    "Farrukhabad", "Ayodhya", "Bulandshahr", "Etawah", "Mirzapur"
  ],
  "West Bengal": [
    "Kolkata", "Howrah", "Siliguri", "Durgapur", "Asansol",
    "Bardhaman", "Malda", "Baharampur", "Habra", "Kharagpur",
    "Shantipur", "Dankuni", "Haldia", "Krishnanagar", "Medinipur",
    "Jalpaiguri", "Balurghat", "Bankura", "Darjeeling", "Alipurduar",
    "Purulia", "Jangipur", "Bangaon", "Cooch Behar", "Raiganj"
  ],
};