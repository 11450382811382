import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBicycle, FaPlus, FaTimes, FaEdit, FaUsers, FaExternalLinkAlt } from 'react-icons/fa';
import axios from '../config/axiosConfig';
import LogoUploader from './ClubOwnerDashboard/LogoUploader';

const DashboardCard = ({ title, children, className }) => (
  <div className={`bg-white rounded-lg shadow-md p-6 ${className}`}>
    <h2 className="text-lg font-semibold mb-4">{title}</h2>
    {children}
  </div>
);

const ActionCard = ({ icon: Icon, label, description, href, onClick }) => (
  <Link
    to={href}
    onClick={onClick}
    className="flex items-start p-4 border rounded-lg transition-all duration-150 hover:border-primary hover:shadow-sm bg-white"
  >
    <div className="shrink-0 mr-4">
      <div className="p-2 rounded-md bg-gray-50 text-primary">
        <Icon className="w-5 h-5" />
      </div>
    </div>
    <div>
      <h3 className="font-medium text-gray-900 mb-1">{label}</h3>
      <p className="text-sm text-gray-500">{description}</p>
    </div>
  </Link>
);

function ClubOwnerDashboard() {
  const [club, setClub] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [coreTeam, setCoreTeam] = useState([]);
  const [supporters, setSupporters] = useState([]);
  const [newCoreTeamMember, setNewCoreTeamMember] = useState("");
  const [newSupporter, setNewSupporter] = useState("");

  useEffect(() => {
    const fetchClub = async () => {
      try {
        const response = await axios.get('/clubs/myclub');
        setClub(response.data);
        setDescription(response.data.description || "");
        setCoreTeam(response.data.coreTeam || []);
        setSupporters(response.data.supporters || []);
      } catch (error) {
        setError(error.response?.status === 404
          ? "It looks like you haven't created a club yet."
          : 'Error retrieving club information. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchClub();
  }, []);

  const saveDescription = async () => {
    try {
      await axios.patch(`/clubs/myclub`, { description, coreTeam, supporters });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating club details:', error);
      setError('Error updating club details.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 p-8 flex items-center justify-center">
        <div className="animate-pulse text-lg text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error && !club) {
    return (
      <div className="min-h-screen bg-gray-50 p-8 flex flex-col items-center justify-center">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full text-center">
          <p className="text-red-500 mb-6">{error}</p>
          <Link
            to="/clubs/new"
            className="inline-flex items-center px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
          >
            <FaPlus className="mr-2" />
            Create New Club
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex items-center space-x-6">
              <div className="w-24 h-24">
                {club.logo ? (
                  <img src={club.logo} alt={club.name} className="w-full h-full rounded-full object-cover" />
                ) : (
                  <LogoUploader clubId={club.clubId} setClub={setClub} />
                )}
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{club.name}</h1>
                <p className="text-gray-500 mt-1">Club Dashboard</p>
              </div>
            </div>
            <div className="mt-4 md:mt-0">
              <Link
                to={`/clubs/${club.clubId}`}
                className="inline-flex items-center px-4 py-2 text-sm border rounded-lg hover:bg-gray-50 transition-colors text-gray-600"
              >
                <FaExternalLinkAlt className="mr-2 w-4 h-4" />
                View Public Page
              </Link>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Quick Actions */}
          <DashboardCard title="Quick Actions" className="md:col-span-2">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <ActionCard
                icon={FaBicycle}
                label="Manage Rides"
                description="View and manage all club rides"
                href="/owner-dashboard/rides"
              />
              <ActionCard
                icon={FaPlus}
                label="Create New Ride"
                description="Set up a new ride event"
                href={`/clubs/${club.clubId}/rides/new`}
              />
              <ActionCard
                icon={FaEdit}
                label="Club Details"
                description="View and update club details"
                href="/owner-dashboard/club-details"
              />
            </div>
          </DashboardCard>

          {/* Stats Card */}
          <DashboardCard title="Club Statistics" className="bg-gradient-to-br from-primary to-primary-dark text-white">
            <div className="space-y-4">
              <div>
                <p className="text-sm opacity-75">Total Members</p>
                <p className="text-2xl font-bold">{coreTeam.length + supporters.length}</p>
              </div>
              <div>
                <p className="text-sm opacity-75">Core Team Members</p>
                <p className="text-2xl font-bold">{coreTeam.length}</p>
              </div>
            </div>
          </DashboardCard>

          {/* Club Description */}
          <DashboardCard title="Club Description" className="md:col-span-2">
            {isEditing ? (
              <div className="space-y-4">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary focus:border-primary"
                  rows={4}
                  placeholder="Enter club description..."
                />
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={saveDescription}
                    className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <p className="text-gray-600">{description || "No description provided."}</p>
                <button
                  onClick={() => setIsEditing(true)}
                  className="inline-flex items-center text-primary hover:text-primary-dark"
                >
                  <FaEdit className="mr-2" />
                  Edit Description
                </button>
              </div>
            )}
          </DashboardCard>

          {/* Team Members */}
          <DashboardCard title="Team Members">
            <div className="space-y-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500 mb-2">Core Team</h3>
                <div className="space-y-2">
                  {coreTeam.map((member, index) => (
                    <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                      <span className="text-sm">{member}</span>
                      <button
                        onClick={() => setCoreTeam(coreTeam.filter((_, i) => i !== index))}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTimes className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500 mb-2">Supporters</h3>
                <div className="space-y-2">
                  {supporters.map((supporter, index) => (
                    <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                      <span className="text-sm">{supporter}</span>
                      <button
                        onClick={() => setSupporters(supporters.filter((_, i) => i !== index))}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTimes className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </DashboardCard>
        </div>
      </div>
    </div>
  );
}

export default ClubOwnerDashboard;