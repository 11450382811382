import React from 'react';

const Testimonials = () => {
  const testimonials = [
    {
      text: `"This platform has transformed my cycling experience. I've met so many amazing people!"`,
      author: 'Aditya B.',
    },
    {
      text: `"I love the variety of rides available. There's always something new to explore."`,
      author: 'Sanjeev S.',
    },
    {
      text: `"Tracking my progress has never been easier. Highly recommend this platform!"`,
      author: 'Parag M.',
    },
  ];

  return (
    <div className="mt-20 px-4 md:px-8">
      <h2 className="text-4xl font-extrabold text-center mb-12 text-gray-800" data-aos="fade-up">
        What Our Riders Say
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonials.map((item, idx) => (
          <div
            key={idx}
            data-aos="fade-up"
            data-aos-delay={idx * 200}
            className="bg-white/70 backdrop-blur-md rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow hover:scale-105 transform"
          >
            <p className="text-gray-600 mb-6 italic">“{item.text.replace(/"/g, '')}”</p>
            <h3 className="text-xl font-semibold text-gray-700">- {item.author}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;