import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Clock, Flag, MapPin, Users, ChevronRight, ChevronDown, ChevronUp } from 'lucide-react';
import SimpleBadge from './SimpleBadge';

const RideHistory = ({ pastRides }) => {
  const [expandedMonths, setExpandedMonths] = useState({});

  const toggleMonth = (yearMonth) => {
    setExpandedMonths(prev => ({
      ...prev,
      [yearMonth]: !prev[yearMonth]
    }));
  };

  const groupedRides = pastRides.reduce((acc, ride) => {
    const date = new Date(ride.rideStartDateTime);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });

    if (!acc[year]) acc[year] = {};
    if (!acc[year][month]) acc[year][month] = [];
    acc[year][month].push(ride);
    return acc;
  }, {});

  const sortedYears = Object.keys(groupedRides).sort((a, b) => b - a);

  const getMonthNumber = (monthName) => {
    return new Date(`${monthName} 1, 2000`).getMonth();
  };

  const getMonthSummary = (rides) => {
    const totalRides = rides.length;
    const finishedRides = rides.filter(ride => ride.Result === 'Finished');
    const totalFinishedDistance = finishedRides.reduce((sum, ride) =>
      sum + parseFloat(ride.rideDistance), 0
    );

    return {
      totalRides,
      totalDistance: totalFinishedDistance.toFixed(1),
      finishedRides: finishedRides.length
    };
  };

  const StatusBadge = ({ result }) => (
    <span className={`px-2 py-1 rounded-full text-xs font-medium ${result === 'Finished'
        ? 'bg-green-100 text-green-800'
        : 'bg-red-100 text-red-800'
      }`}>
      {result}
    </span>
  );

  return (
    <div className="w-full max-w-7xl mx-auto px-4">
      <h2 className="text-2xl font-bold mb-6 text-foreground">Ride History</h2>

      <div className="space-y-6">
        {sortedYears.map(year => (
          <div key={year} className="rounded-lg border border-border overflow-hidden">
            <div className="bg-muted/50 px-4 py-2 border-b border-border">
              <h3 className="text-lg font-semibold text-foreground">{year}</h3>
            </div>

            <div className="divide-y divide-border">
              {Object.keys(groupedRides[year])
                .sort((a, b) => getMonthNumber(b) - getMonthNumber(a))
                .map(month => {
                  const monthKey = `${year}-${month}`;
                  const isExpanded = expandedMonths[monthKey];
                  const rides = groupedRides[year][month].sort((a, b) =>
                    new Date(b.rideStartDateTime) - new Date(a.rideStartDateTime)
                  );
                  const summary = getMonthSummary(rides);

                  return (
                    <div key={monthKey}>
                      <button
                        onClick={() => toggleMonth(monthKey)}
                        className="w-full bg-muted/30 px-4 py-2.5 border-b border-border hover:bg-muted/40 transition-colors"
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            {isExpanded ? (
                              <ChevronUp className="w-4 h-4 text-muted-foreground" />
                            ) : (
                              <ChevronDown className="w-4 h-4 text-muted-foreground" />
                            )}
                            <h4 className="font-medium text-foreground">{month}</h4>
                          </div>
                          <div className="flex items-center gap-6 text-sm text-muted-foreground">
                            <span>{summary.totalRides} {summary.totalRides === 1 ? 'ride' : 'rides'}</span>
                            <span>{summary.totalDistance} km</span>
                            <span>{summary.finishedRides} completed</span>
                          </div>
                        </div>
                      </button>

                      {isExpanded && (
                        <table className="w-full">
                          <tbody className="divide-y divide-border">
                            {rides.map((ride) => (
                              <tr key={ride.RideID} className="group hover:bg-muted/40">
                                <td className="pl-4 py-3 w-16">
                                  <div className="text-center">
                                    <span className="text-2xl font-bold text-foreground">
                                      {new Date(ride.rideStartDateTime).getDate()}
                                    </span>
                                  </div>
                                </td>
                                <td className="py-3 pl-2">
                                  <Link
                                    to={`/rides/${ride.RideID}`}
                                    className="text-primary hover:text-primary/90 hover:underline font-medium"
                                  >
                                    {ride.rideName || 'Untitled Ride'}
                                  </Link>
                                  <div className="flex items-center gap-4 mt-1 text-sm text-muted-foreground">
                                    <div className="flex items-center gap-1">
                                      <MapPin className="w-4 h-4" />
                                      {ride.rideDistance} km
                                    </div>
                                    <div className="flex items-center gap-1">
                                      <Users className="w-4 h-4" />
                                      {ride.ClubName || 'N/A'}
                                    </div>
                                    {ride.TotalTime && (
                                      <div className="flex items-center gap-1">
                                        <Clock className="w-4 h-4" />
                                        {ride.TotalTime}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td className="py-3 px-4 text-right">
                                  <div className="flex items-center justify-end gap-3">
                                    {ride.Result && <StatusBadge result={ride.Result} />}
                                    <div className="w-24 h-16 flex items-center justify-center">
                                      <div className="scale-75 origin-center">
                                        <SimpleBadge ride={ride} />
                                      </div>
                                    </div>
                                    <ChevronRight className="w-4 h-4 text-muted-foreground opacity-0 group-hover:opacity-100 transition-opacity" />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </div>

      {sortedYears.length === 0 && (
        <div className="text-center py-12">
          <p className="text-muted-foreground text-lg">No ride history available</p>
        </div>
      )}
    </div>
  );
};

export default RideHistory;