import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import AdminLayout from './AdminLayout';
import AdminAccessCheck from './AdminAccessCheck';
import { Loader2 } from 'lucide-react';

function MasterAdminPage() {
  const { rideId } = useParams();

  // Fetch main ride details
  const {
    data: rideData,
    isLoading: rideLoading,
    isError: rideError
  } = useQuery({
    queryKey: ['rideDetails', rideId],
    queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    staleTime: 1000 * 60 * 5,
  });

  // Fetch all riders with check-ins (and results)
  const {
    data: checkinsData,
    isLoading: checkinsLoading,
    isError: checkinsError
  } = useQuery({
    queryKey: ['allCheckins', rideId],
    queryFn: () => axios.get(`/rides/${rideId}/all-checkins`).then(res => res.data),
    enabled: !!rideData,  // only fetch after ride details are loaded
    staleTime: 1000 * 60 * 5,
  });

  if (rideLoading || checkinsLoading) {
    return (
      <AdminLayout title="Loading Dashboard...">
        <div className="flex flex-col items-center justify-center py-12">
          <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
          <p className="mt-4 text-gray-600">Loading admin dashboard...</p>
        </div>
      </AdminLayout>
    );
  }

  if (rideError || checkinsError || !rideData) {
    return (
      <AdminLayout title="Error">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
          <p className="text-red-700">Error loading data. Please try again.</p>
        </div>
      </AdminLayout>
    );
  }

  // For Access Checking
  const isClubOwner = rideData.user?.isClubOwner;

  // If checkins not yet loaded, fallback to empty array
  const riders = checkinsData || [];

  // Compute various stats
  const totalRiders = riders.length;
  const finishedCount = riders.filter(r => r.Result === 'Finished').length;
  const lateFinishCount = riders.filter(r => r.Result === 'Late Finish').length;
  const dnfCount = riders.filter(r => r.Result === 'DNF').length;
  const dnsCount = riders.filter(r => r.Result === 'DNS').length;
  const pendingCount = riders.filter(r => !r.Result).length;

  // Count how many have any payment
  const paidCount = riders.filter(
    r => r.payment && (r.payment.status === 'paid' || r.payment.status === 'true' || r.payment.offlinePayment)
  ).length;

  // Quick details about the ride
  const rideName = rideData.name || 'Untitled Ride';
  const rideDistance = rideData.distance || 0;
  const rideStartTime = rideData.startDateTime ? new Date(rideData.startDateTime).toLocaleString() : 'N/A';
  const rideRegClose = rideData.registrationClosingDateTime
    ? new Date(rideData.registrationClosingDateTime).toLocaleString()
    : 'N/A';
  const timeControlType = rideData.timeControlType || 'N/A';

  return (
    <AdminAccessCheck isAdmin={isClubOwner}>
      <AdminLayout title="Event Admin Dashboard">
        <div className="space-y-6">

          {/* Stats Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* Total Riders */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <p className="text-sm text-gray-600">Total Registered Riders</p>
              <p className="text-2xl font-bold text-gray-900">{totalRiders}</p>
            </div>
            {/* Paid Riders */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <p className="text-sm text-gray-600">Paid Riders</p>
              <p className="text-2xl font-bold text-blue-600">{paidCount}</p>
            </div>
            {/* Finished */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <p className="text-sm text-gray-600">Finished</p>
              <p className="text-2xl font-bold text-green-600">{finishedCount}</p>
            </div>
            {/* Pending */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <p className="text-sm text-gray-600">Pending</p>
              <p className="text-2xl font-bold text-orange-600">{pendingCount}</p>
            </div>
          </div>

          {/* Additional Stats */}
          <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
            {/* Late Finish */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <p className="text-sm text-gray-600">Late Finish</p>
              <p className="text-2xl font-bold text-yellow-600">{lateFinishCount}</p>
            </div>
            {/* DNF */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <p className="text-sm text-gray-600">DNF</p>
              <p className="text-2xl font-bold text-red-600">{dnfCount}</p>
            </div>
            {/* DNS */}
            <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
              <p className="text-sm text-gray-600">DNS</p>
              <p className="text-2xl font-bold text-gray-800">{dnsCount}</p>
            </div>
          </div>

          {/* Ride Overview Card */}
          <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
            <h2 className="text-xl font-bold text-gray-800 mb-3">Ride Overview</h2>
            <div className="text-sm text-gray-700 space-y-2">
              <p><span className="font-semibold">Ride Name:</span> {rideName}</p>
              <p><span className="font-semibold">Distance:</span> {rideDistance} km</p>
              <p><span className="font-semibold">Start Time:</span> {rideStartTime}</p>
              <p><span className="font-semibold">Registration Closes:</span> {rideRegClose}</p>
              <p><span className="font-semibold">Time Control Type:</span> {timeControlType}</p>
            </div>
          </div>

        </div>
      </AdminLayout>
    </AdminAccessCheck>
  );
}

export default MasterAdminPage;