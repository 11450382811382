export const APP_VERSION = {
  major: 1,
  minor: 0,
  patch: 26,
  buildDate: '2024-12-19T11:57:52Z',
};

export const getVersionString = () => {
  const { major, minor, patch, buildDate } = APP_VERSION;
  return `v${major}.${minor}.${patch} (${new Date(buildDate).toLocaleString()})`;
};
