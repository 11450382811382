import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import ErrorMessage from '../Message';
import AdminLayout from './AdminLayout';
import AdminAccessCheck from './AdminAccessCheck';
import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import Select from 'react-select';
import {
    CURATED_TIMEZONES,
    formatTimezone,
    getDefaultTimezone,
} from '../../utils/timezoneUtils';

function RideInformationEditor() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();

    // Local component states
    const [editMode, setEditMode] = useState(false);
    const [rideName, setRideName] = useState('');
    const [distance, setDistance] = useState('');
    const [timeControlType, setTimeControlType] = useState('');
    const [timezone, setTimezone] = useState(getDefaultTimezone());
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [regClosingDate, setRegClosingDate] = useState('');
    const [regClosingTime, setRegClosingTime] = useState('');

    const [isClubOwner, setIsClubOwner] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState('');
    const [saveError, setSaveError] = useState('');

    // Prepare options for timezone dropdown
    const timezoneOptions = CURATED_TIMEZONES.map((tz) => ({
        value: tz.name,
        label: `${tz.abbr} - ${tz.name} (GMT${tz.offset})`,
    }));

    // Fetch ride details
    const {
        data: ride,
        error,
        isLoading,
    } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then((res) => res.data),
        staleTime: 5 * 60 * 1000,
    });

    // Populate local states when ride data changes
    useEffect(() => {
        if (ride) {
            setRideName(ride.name || '');
            setDistance(ride.distance || '');
            setTimeControlType(ride.timeControlType || '');
            setTimezone(ride.timezone || getDefaultTimezone());
            setIsClubOwner(ride?.user?.isClubOwner || false);

            const start = new Date(ride.startDateTime);
            setStartDate(start.toISOString().split('T')[0]);
            setStartTime(start.toTimeString().slice(0, 5));

            const regClose = new Date(ride.registrationClosingDateTime);
            setRegClosingDate(regClose.toISOString().split('T')[0]);
            setRegClosingTime(regClose.toTimeString().slice(0, 5));
        }
    }, [ride]);

    // Helper to format date/time for PATCH request (no timezone offset)
    const formatDateWithoutTimezone = (date, time) => {
        const [year, month, day] = date.split('-').map(Number);
        const [hours, minutes] = time.split(':').map(Number);
        const pad = (num) => num.toString().padStart(2, '0');
        return `${year}-${pad(month)}-${pad(day)}T${pad(hours)}:${pad(minutes)}:00`;
    };

    // Mutation to save data
    const mutation = useMutation({
        mutationFn: async (updatedData) => {
            // We still pass distance/timeControlType and the start date/time to preserve them.
            // But the user can't change distance or timeControlType or start date in the UI.
            const formattedStart = formatDateWithoutTimezone(startDate, startTime);
            const formattedRegClose = formatDateWithoutTimezone(
                regClosingDate,
                regClosingTime
            );

            return axios.patch(`/rides/${rideId}`, {
                ...updatedData,
                startDateTime: formattedStart,
                registrationClosingDateTime: formattedRegClose,
                timezone,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setEditMode(false);
            setSaveError('');
            setSaveSuccess('Ride details updated successfully!');
            setTimeout(() => setSaveSuccess(''), 3000);
        },
        onError: (error) => {
            const errorMessage =
                error.response?.data?.message || error.message || 'Failed to update ride';
            const errorDetails = error.response?.data?.error || '';
            setSaveError(`${errorMessage} ${errorDetails}`);
        },
    });

    // Save
    const handleSaveClick = () => {
        // Basic validation
        if (!startDate || !startTime || !regClosingDate || !regClosingTime) {
            setSaveError('Please fill in all date and time fields');
            return;
        }
        setSaveError('');

        // Distance, Time Control Type, and Start Date are read-only in UI,
        // so they remain as-is:
        mutation.mutate({
            name: rideName,
            distance,           // Still included, but read-only in UI
            timeControlType,    // Still included, but read-only in UI
            clubId: ride?.clubId,
            isTimeControlled: true,
        });
    };

    // Cancel
    const handleCancelClick = () => {
        setEditMode(false);
        setSaveError('');
        // Reset form to original ride data
        if (ride) {
            setRideName(ride.name || '');
            setDistance(ride.distance || '');
            setTimeControlType(ride.timeControlType || '');
            setTimezone(ride.timezone || getDefaultTimezone());

            const start = new Date(ride.startDateTime);
            setStartDate(start.toISOString().split('T')[0]);
            setStartTime(start.toTimeString().slice(0, 5));

            const regClose = new Date(ride.registrationClosingDateTime);
            setRegClosingDate(regClose.toISOString().split('T')[0]);
            setRegClosingTime(regClose.toTimeString().slice(0, 5));
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <ErrorMessage message="Error fetching ride details." />;
    }

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Event Details">
                {/* Success / Error Messages */}
                {saveSuccess && (
                    <div className="mb-4 rounded-md bg-green-50 p-3 border-l-4 border-green-500 text-green-700">
                        {saveSuccess}
                    </div>
                )}
                {saveError && (
                    <div className="mb-4 rounded-md bg-red-50 p-3 border-l-4 border-red-500 text-red-700">
                        {saveError}
                    </div>
                )}

                {!editMode ? (
                    // READ-ONLY VIEW
                    <div className="bg-white p-4 rounded shadow-sm">
                        <div className="flex justify-end mb-2">
                            <button
                                onClick={() => setEditMode(true)}
                                className="inline-flex items-center gap-2 bg-blue-600 text-white px-3 py-2 rounded hover:bg-blue-700"
                            >
                                <FaEdit />
                                Edit Details
                            </button>
                        </div>
                        <table className="w-full text-sm">
                            <tbody>
                                <tr>
                                    <td className="py-2 font-semibold w-40">Ride Name:</td>
                                    <td>{rideName}</td>
                                </tr>
                                <tr>
                                    <td className="py-2 font-semibold w-40">Distance:</td>
                                    <td>{distance || '—'}</td>
                                </tr>
                                <tr>
                                    <td className="py-2 font-semibold w-40">Time Control:</td>
                                    <td>{timeControlType || '—'}</td>
                                </tr>
                                <tr>
                                    <td className="py-2 font-semibold w-40">Timezone:</td>
                                    <td>{formatTimezone(timezone)}</td>
                                </tr>
                                <tr>
                                    <td className="py-2 font-semibold w-40">Start Date & Time:</td>
                                    <td>
                                        {new Date(ride.startDateTime).toLocaleString([], {
                                            dateStyle: 'short',
                                            timeStyle: 'short',
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="py-2 font-semibold w-40">
                                        Registration Closes:
                                    </td>
                                    <td>
                                        {new Date(ride.registrationClosingDateTime).toLocaleString(
                                            [],
                                            {
                                                dateStyle: 'short',
                                                timeStyle: 'short',
                                            }
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    // EDIT MODE
                    <div className="bg-white p-4 rounded shadow-sm space-y-4">
                        <div className="grid gap-4 md:grid-cols-2">
                            {/* Ride Name */}
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Ride Name
                                </label>
                                <input
                                    type="text"
                                    value={rideName}
                                    onChange={(e) => setRideName(e.target.value)}
                                    className="w-full rounded border-gray-300 focus:ring focus:ring-blue-400 px-3 py-2"
                                />
                            </div>

                            {/* Distance (Read-Only) */}
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Distance <span className="text-xs text-gray-500">(cannot be changed)</span>
                                </label>
                                <input
                                    type="text"
                                    value={distance}
                                    readOnly
                                    disabled
                                    className="w-full rounded border-gray-200 bg-gray-100 text-gray-500 cursor-not-allowed px-3 py-2"
                                />
                            </div>
                        </div>

                        {/* Time Control Type (Read-Only) */}
                        <div>
                            <label className="block text-sm font-medium mb-1">
                                Time Control Type <span className="text-xs text-gray-500">(cannot be changed)</span>
                            </label>
                            <input
                                type="text"
                                value={timeControlType}
                                readOnly
                                disabled
                                className="w-full rounded border-gray-200 bg-gray-100 text-gray-500 cursor-not-allowed px-3 py-2"
                            />
                        </div>

                        {/* Timezone */}
                        <div>
                            <label className="block text-sm font-medium mb-1">Timezone</label>
                            <Select
                                options={timezoneOptions}
                                value={timezoneOptions.find((opt) => opt.value === timezone)}
                                onChange={(option) => setTimezone(option.value)}
                                isSearchable
                                placeholder="Search for a timezone..."
                            />
                        </div>

                        {/* START DATE (READ-ONLY) / START TIME */}
                        <div className="grid gap-4 md:grid-cols-2">
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Start Date <span className="text-xs text-gray-500">(cannot be changed)</span>
                                </label>
                                <input
                                    type="text"
                                    value={startDate}
                                    readOnly
                                    disabled
                                    className="w-full rounded border-gray-200 bg-gray-100 text-gray-500 cursor-not-allowed px-3 py-2"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Start Time
                                </label>
                                <input
                                    type="time"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                    className="w-full rounded border-gray-300 focus:ring focus:ring-blue-400 px-3 py-2"
                                />
                            </div>
                        </div>

                        {/* REGISTRATION CLOSING DATE / TIME */}
                        <div className="grid gap-4 md:grid-cols-2">
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Reg. Closing Date
                                </label>
                                <input
                                    type="date"
                                    value={regClosingDate}
                                    onChange={(e) => setRegClosingDate(e.target.value)}
                                    className="w-full rounded border-gray-300 focus:ring focus:ring-blue-400 px-3 py-2"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Reg. Closing Time
                                </label>
                                <input
                                    type="time"
                                    value={regClosingTime}
                                    onChange={(e) => setRegClosingTime(e.target.value)}
                                    className="w-full rounded border-gray-300 focus:ring focus:ring-blue-400 px-3 py-2"
                                />
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className="flex justify-end gap-4 mt-4">
                            <button
                                onClick={handleSaveClick}
                                className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                <FaSave />
                                Save
                            </button>
                            <button
                                onClick={handleCancelClick}
                                className="flex items-center gap-2 px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
                            >
                                <FaTimes />
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default RideInformationEditor;