import React from 'react';
import { FaMapMarkedAlt, FaCheckCircle, FaTrophy } from 'react-icons/fa';

const features = [
  {
    icon: <FaMapMarkedAlt />,
    title: 'Digital Control Points',
    description: 'Validate your ride progress with our digital check-in system. GPS verification and photo uploads ensure accurate completion tracking.',
  },
  {
    icon: <FaCheckCircle />,
    title: 'Official Homologation',
    description: 'Earn permanent homologations and digital certificates upon successful completion of your rides.',
  },
  {
    icon: <FaTrophy />,
    title: 'Achievement Tracking',
    description: 'Build your cycling profile with verified completions, showcase your achievements, and track your progress over time.',
  },
];

const FeaturesSection = () => {
  return (
    <div
      className="py-16 px-8 bg-gradient-to-r from-blue-50 to-indigo-100 text-center"
    >
      <h2 className="text-4xl font-bold mb-12 text-gray-800" data-aos="fade-up">
        Key Features
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            data-aos="fade-up"
            data-aos-delay={index * 200}
            className="flex flex-col items-center p-6 bg-white/80 backdrop-blur-md rounded-lg shadow-lg transition-transform transform hover:scale-105"
          >
            <div className="text-6xl text-indigo-600 mb-4">{feature.icon}</div>
            <h3 className="text-2xl font-semibold mb-2 text-gray-700">{feature.title}</h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;