import React, { useEffect, useState } from 'react';
import axios from '../../config/axiosConfig';
import { FaEdit } from 'react-icons/fa';

function ClubDetailsPage() {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);

    // Fetched club data
    const [club, setClub] = useState(null);

    // Editing states
    const [isEditing, setIsEditing] = useState(false);
    const [clubName, setClubName] = useState('');
    const [description, setDescription] = useState('');
    const [coreTeamInput, setCoreTeamInput] = useState('');
    const [supportersInput, setSupportersInput] = useState('');
    const [razorpayId, setRazorpayId] = useState('');

    useEffect(() => {
        fetchClubDetails();
    }, []);

    const fetchClubDetails = async () => {
        setFetchLoading(true);
        setError(null);
        try {
            const response = await axios.get('/clubs/myclub');
            setClub(response.data);

            // Pre-fill form fields
            setClubName(response.data.name || '');
            setDescription(response.data.description || '');
            setCoreTeamInput((response.data.coreTeam || []).join(', '));
            setSupportersInput((response.data.supporters || []).join(', '));
            setRazorpayId(response.data.razorpayId || '');
        } catch (err) {
            console.error('Error fetching club details:', err);
            setError('Error fetching club details. Please try again.');
        } finally {
            setFetchLoading(false);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        setSaving(true);
        setError(null);
        setSuccessMessage(null);

        // Prepare data for PATCH
        const coreTeamArray = coreTeamInput
            .split(',')
            .map(member => member.trim())
            .filter(member => member !== '');

        const supportersArray = supportersInput
            .split(',')
            .map(supporter => supporter.trim())
            .filter(supporter => supporter !== '');

        const payload = {
            name: clubName,
            description,
            coreTeam: coreTeamArray,
            supporters: supportersArray,
            razorpayId,
        };

        try {
            await axios.patch('/clubs/myclub', payload);
            // Re-fetch the club so we have updated fields
            await fetchClubDetails();
            setIsEditing(false);
            setSuccessMessage('Club details saved successfully!');
        } catch (err) {
            console.error('Error saving club details:', err);
            setError('Error saving club details. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    const handleCancelEdit = () => {
        if (!club) {
            setIsEditing(false);
            return;
        }
        // Revert to stored club data
        setClubName(club.name || '');
        setDescription(club.description || '');
        setCoreTeamInput((club.coreTeam || []).join(', '));
        setSupportersInput((club.supporters || []).join(', '));
        setRazorpayId(club.razorpayId || '');
        setIsEditing(false);
        setError(null);
        setSuccessMessage(null);
    };

    if (fetchLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p className="text-gray-600">Loading club details...</p>
            </div>
        );
    }

    if (error && !club) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p className="text-red-600">{error}</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex justify-center bg-background-light p-4">
            <div className="max-w-3xl w-full bg-white p-6 rounded shadow-md">
                <h1 className="text-2xl font-bold text-primary mb-4">Club Details</h1>

                {/* Success and error messages */}
                {successMessage && (
                    <div className="mb-4 p-3 rounded bg-green-100 text-green-800">
                        {successMessage}
                    </div>
                )}
                {error && (
                    <p className="text-red-600 mb-4">{error}</p>
                )}

                {!isEditing && (
                    <div className="space-y-4">
                        <div>
                            <label className="block font-semibold">Club Name:</label>
                            <p className="text-gray-700">{clubName || 'N/A'}</p>
                        </div>
                        <div>
                            <label className="block font-semibold">Description:</label>
                            <p className="text-gray-700 whitespace-pre-line">
                                {description || 'N/A'}
                            </p>
                        </div>
                        <div>
                            <label className="block font-semibold">Core Team:</label>
                            {club?.coreTeam && club.coreTeam.length > 0 ? (
                                <ul className="list-disc list-inside text-gray-700">
                                    {club.coreTeam.map((member, idx) => (
                                        <li key={idx}>{member}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-gray-700">No core team members.</p>
                            )}
                        </div>
                        <div>
                            <label className="block font-semibold">Supporters:</label>
                            {club?.supporters && club.supporters.length > 0 ? (
                                <ul className="list-disc list-inside text-gray-700">
                                    {club.supporters.map((supporter, idx) => (
                                        <li key={idx}>{supporter}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-gray-700">No supporters.</p>
                            )}
                        </div>
                        <div>
                            <label className="block font-semibold">Razorpay ID:</label>
                            <p className="text-gray-700">{razorpayId || 'N/A'}</p>
                        </div>

                        <button
                            onClick={() => setIsEditing(true)}
                            className="inline-flex items-center px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
                        >
                            <FaEdit className="mr-2" />
                            Edit
                        </button>
                    </div>
                )}

                {isEditing && (
                    <form onSubmit={handleSave} className="space-y-4">
                        <div>
                            <label className="block font-semibold mb-1">
                                Club Name
                            </label>
                            <input
                                type="text"
                                value={clubName}
                                onChange={(e) => setClubName(e.target.value)}
                                className="w-full p-2 border rounded"
                                required
                            />
                        </div>
                        <div>
                            <label className="block font-semibold mb-1">
                                Description
                            </label>
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="w-full p-2 border rounded"
                                rows="5"
                            />
                        </div>
                        <div>
                            <label className="block font-semibold mb-1">
                                Core Team (Comma-separated URNs)
                            </label>
                            <input
                                type="text"
                                value={coreTeamInput}
                                onChange={(e) => setCoreTeamInput(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block font-semibold mb-1">
                                Supporters (Comma-separated URNs)
                            </label>
                            <input
                                type="text"
                                value={supportersInput}
                                onChange={(e) => setSupportersInput(e.target.value)}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block font-semibold mb-1">
                                Razorpay ID
                            </label>
                            <input
                                type="text"
                                value={razorpayId}
                                onChange={(e) => setRazorpayId(e.target.value)}
                                className="w-full p-2 border rounded"
                                required
                            />
                        </div>

                        <div className="flex justify-end space-x-4 pt-2">
                            <button
                                type="button"
                                onClick={handleCancelEdit}
                                disabled={saving}
                                className="px-4 py-2 border border-gray-300 text-gray-700 rounded hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={saving}
                                className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
                            >
                                {saving ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}

export default ClubDetailsPage;