import React from 'react';
import { jsPDF } from 'jspdf';
import logo from '../../assets/logo.png';
import { getLocalStorage } from '../../lib/storage';

function PDFDownloadButton({ leftSideData, idTokenPayload, children }) {
  const handleDownloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4'
    });

    const x = 10;
    const y = 30;
    const cardWidth = 300;
    const cardHeight = 200;

    doc.setLineWidth(2);
    doc.rect(x, y, cardWidth, cardHeight);

    const logoImg = new Image();
    logoImg.src = logo;
    logoImg.onload = () => {
      const logoSize = 160;
      const logoX = x + (cardWidth / 2) - (logoSize / 2);
      const logoY = y + (cardHeight / 2) - (logoSize / 2);
      doc.addImage(logoImg, 'PNG', logoX, logoY, logoSize, logoSize, '', 'FAST');

      doc.setFontSize(20);
      doc.text('ultra-rides.com', x + 20, y + 20);

      doc.setFontSize(80);
      doc.text(`URN: ${leftSideData.customUserId}`, x + 20, y + 80);
      doc.setFontSize(14);
      doc.text(`Name: ${leftSideData.name}`, x + 20, y + 110);
      doc.text(`Phone: ${getLocalStorage('phoneNumber') || idTokenPayload?.phone_number || ''}`, x + 20, y + 130);
      doc.text(`Emergency Contact: ${getLocalStorage('emergencyContactName') || idTokenPayload?.['custom:ec_name'] || ''}`, x + 20, y + 150);
      doc.text(`Emergency Phone: ${getLocalStorage('emergencyContactPhone') || idTokenPayload?.['custom:ec_phone_number'] || ''}`, x + 20, y + 170);
      doc.text(`Blood Group: ${getLocalStorage('bloodGroup') || idTokenPayload?.['custom:blood_group'] || ''}`, x + 20, y + 190);

      doc.save('rider_card.pdf');
    };
  };

  return (
    <button onClick={handleDownloadPDF}>
      {children}
    </button>
  );
}

export default PDFDownloadButton;