import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import HeroSection from './HeroSection';
import FeaturesSection from './FeaturesSection';
import ForClubOwners from './ForClubOwners';
import ForRiders from './ForRiders';
import HowItWorks from './HowItWorks';
import InfoCards from './InfoCards';
import Testimonials from './Testimonials';
import MobileApps from './MobileApps';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: false,
    });
  }, []);

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-start bg-gray-50 overflow-x-hidden">
      <HeroSection />

      {/* Features Section */}
      <section data-aos="fade-up" className="w-full">
        <FeaturesSection />
      </section>

      {/* For Club Owners */}
      <section data-aos="fade-up" className="w-full">
        <ForClubOwners />
      </section>

      {/* For Riders */}
      <section data-aos="fade-up" className="w-full">
        <ForRiders />
      </section>

      {/* How It Works */}
      <section data-aos="fade-up" className="w-full">
        <HowItWorks />
      </section>

      {/* InfoCards */}
      <section data-aos="fade-up" className="w-full mt-12 px-4 md:px-8">
        <InfoCards />
      </section>

      {/* Testimonials */}
      <section data-aos="fade-up" className="w-full mt-12 px-4 md:px-8">
        <Testimonials />
      </section>

      {/* Event Discovery Section */}
      <section data-aos="fade-up" className="w-full mt-12 px-4 md:px-8">
        <h2 className="text-4xl font-bold mb-8 text-gray-800 text-center">Event Discovery</h2>
        <div className="bg-white/80 backdrop-blur-md rounded-lg shadow-md p-6 text-center">
          <h3 className="text-2xl font-bold mb-2 text-gray-800">Discover &amp; Organize Events</h3>
          <p className="text-gray-600 mb-4">
            Explore a variety of upcoming cycling, running, triathlon, and other endurance events
            happening near you. As an organizer, you can easily add your event to reach a wide
            community of riders, runners, and enthusiasts.
          </p>
          <p className="text-gray-600 mb-4">
            Whether you&apos;re looking to join your next epic challenge or promote your own event,
            UltraRides makes it simple and rewarding.
          </p>
          <Link
            to="/events"
            className="inline-block bg-indigo-600 text-white py-2 px-4 rounded-full hover:bg-indigo-700 transition"
          >
            Explore Events
          </Link>
          <div className="text-center mt-4 text-gray-500">
            <Link to="/events/new" className="text-indigo-600 hover:underline">
              Organize and List Your Event
            </Link>
          </div>
        </div>
      </section>

      {/* MobileApps */}
      <section data-aos="fade-up" className="w-full mt-12">
        <MobileApps />
      </section>

      {/* Quick Links */}
      <section
        data-aos="fade-up"
        className="relative z-10 my-16 px-4 md:px-8 grid grid-cols-1 lg:grid-cols-3 gap-12 text-center w-full"
      >
        <Link
          to="/about"
          className="bg-white/80 backdrop-filter backdrop-blur-lg shadow-lg rounded-lg p-8 hover:scale-105 hover:shadow-xl transition-all group"
        >
          <h2 className="text-3xl font-semibold mb-2 text-gray-700 group-hover:text-gray-900">
            About Ultra-Rides
          </h2>
          <p className="text-gray-500 group-hover:text-gray-700 transition-colors">
            Learn more about our mission and how we connect riders and clubs.
          </p>
        </Link>
        <Link
          to="/rides"
          className="bg-white/80 backdrop-filter backdrop-blur-lg shadow-lg rounded-lg p-8 hover:scale-105 hover:shadow-xl transition-all group"
        >
          <h2 className="text-3xl font-semibold mb-2 text-gray-700 group-hover:text-gray-900">
            Upcoming Rides
          </h2>
          <p className="text-gray-500 group-hover:text-gray-700 transition-colors">
            Explore our upcoming rides and join us on an epic journey.
          </p>
        </Link>
        <Link
          to="/create-account"
          className="bg-white/80 backdrop-filter backdrop-blur-lg shadow-lg rounded-lg p-8 hover:scale-105 hover:shadow-xl transition-all group"
        >
          <h2 className="text-3xl font-semibold mb-2 text-gray-700 group-hover:text-gray-900">
            Join Our Community
          </h2>
          <p className="text-gray-500 group-hover:text-gray-700 transition-colors">
            Sign up as a rider or club owner and be part of our vibrant community.
          </p>
        </Link>
      </section>
    </div>
  );
};

export default Home;