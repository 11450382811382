import React, { useState } from 'react';
import RiderRow from './RiderRow';
import { Search, Filter, Download, Users, CheckCircle, XCircle, Clock, AlertTriangle, AlertOctagon } from 'lucide-react';

function RidersTable({ riders, setRiders, controlPoints, handleControlClick, handleStatusChange, handleClearResult, showToast }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('all');
    const [sortConfig, setSortConfig] = useState({ key: 'urn', direction: 'asc' });

    // Filter riders based on search and status
    const filteredRiders = riders.filter(rider => {
        const matchesSearch = (rider.URN?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            rider.Name?.toLowerCase().includes(searchTerm.toLowerCase()));
        
        if (filterStatus === 'all') return matchesSearch;
        if (filterStatus === 'pending') return matchesSearch && !rider.Result;
        return matchesSearch && rider.Result === filterStatus;
    });

    // Handle sorting
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });

        const sorted = [...filteredRiders].sort((a, b) => {
            if (key === 'urn') {
                const urnA = parseInt(a.URN || '0', 10);
                const urnB = parseInt(b.URN || '0', 10);
                return direction === 'asc' ? urnA - urnB : urnB - urnA;
            }
            if (key === 'name') {
                const nameA = (a.Name || '').toLowerCase();
                const nameB = (b.Name || '').toLowerCase();
                return direction === 'asc' ? 
                    nameA.localeCompare(nameB) : 
                    nameB.localeCompare(nameA);
            }
            return 0;
        });

        setRiders(sorted);
    };

    // Handle export
    const handleExport = () => {
        const headers = ['URN', 'Name', 'Status', 'Time', 'Control Points'];
        const csvContent = [
            headers.join(','),
            ...filteredRiders.map(rider => [
                rider.URN || '',
                rider.Name || '',
                rider.Result || 'Not Set',
                rider.TotalTime || '-',
                rider.ControlPoints?.length || 0
            ].join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `riders-data-${new Date().toISOString().slice(0,10)}.csv`;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    // Stats calculations
    const stats = {
        total: riders.length,
        finished: riders.filter(r => r.Result === 'Finished').length,
        lateFinish: riders.filter(r => r.Result === 'Late Finish').length,
        dnf: riders.filter(r => r.Result === 'DNF').length,
        dns: riders.filter(r => r.Result === 'DNS').length,
        pending: riders.filter(r => !r.Result).length
    };

    // Stats card component
    const StatsCard = ({ title, count, status, icon: Icon, colorClass }) => (
        <button
            onClick={() => setFilterStatus(status)}
            className={`relative bg-white p-4 rounded-lg shadow border border-gray-200 
                      transition-all duration-200 hover:shadow-md transform hover:-translate-y-1
                      ${filterStatus === status ? 'ring-2 ring-blue-500' : ''}
                      group`}
        >
            <div className="absolute top-2 right-2 text-gray-400 group-hover:text-gray-600">
                <Filter className="h-4 w-4" />
            </div>
            <div className="flex items-start space-x-3">
                <div className={`p-2 rounded-lg ${colorClass}`}>
                    <Icon className="h-6 w-6" />
                </div>
                <div>
                    <div className="text-sm text-gray-600 group-hover:text-gray-900">{title}</div>
                    <div className={`text-2xl font-bold ${colorClass.replace('bg-', 'text-').replace('/10', '')}`}>
                        {count}
                    </div>
                </div>
            </div>
        </button>
    );

    return (
        <div className="space-y-4">
            {/* Search and Export */}
            <div className="flex flex-col sm:flex-row gap-4 justify-between">
                <div className="relative flex-1">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                    <input
                        type="text"
                        placeholder="Search by URN or Name..."
                        className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <button
                    onClick={handleExport}
                    className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                >
                    <Download className="h-4 w-4" />
                    Export
                </button>
            </div>

            {/* Stats Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4">
                <StatsCard
                    title="Total Riders"
                    count={stats.total}
                    status="all"
                    icon={Users}
                    colorClass="bg-blue-500/10 text-blue-600"
                />
                <StatsCard
                    title="Finished"
                    count={stats.finished}
                    status="Finished"
                    icon={CheckCircle}
                    colorClass="bg-green-500/10 text-green-600"
                />
                <StatsCard
                    title="Late Finish"
                    count={stats.lateFinish}
                    status="Late Finish"
                    icon={AlertOctagon}
                    colorClass="bg-yellow-500/10 text-yellow-600"
                />
                <StatsCard
                    title="DNF"
                    count={stats.dnf}
                    status="DNF"
                    icon={XCircle}
                    colorClass="bg-red-500/10 text-red-600"
                />
                <StatsCard
                    title="DNS"
                    count={stats.dns}
                    status="DNS"
                    icon={AlertTriangle}
                    colorClass="bg-gray-500/10 text-gray-600"
                />
                <StatsCard
                    title="Pending"
                    count={stats.pending}
                    status="pending"
                    icon={Clock}
                    colorClass="bg-orange-500/10 text-orange-600"
                />
            </div>

            {/* Active Filters Display */}
            {filterStatus !== 'all' && (
                <div className="flex items-center gap-2 p-2 bg-blue-50 rounded-lg">
                    <Filter className="h-4 w-4 text-blue-500" />
                    <span className="text-sm text-blue-700">
                        Showing {filterStatus === 'pending' ? 'pending riders' : `riders with status: ${filterStatus}`}
                    </span>
                    <button
                        onClick={() => setFilterStatus('all')}
                        className="ml-auto text-blue-500 hover:text-blue-700"
                    >
                        <XCircle className="h-4 w-4" />
                    </button>
                </div>
            )}

            {/* Table */}
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr className="bg-gray-50">
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleSort('urn')}>
                                    URN
                                    {sortConfig.key === 'urn' && (
                                        <span className="ml-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                                    )}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleSort('name')}>
                                    Name
                                    {sortConfig.key === 'name' && (
                                        <span className="ml-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                                    )}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Control Points
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status & Time
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredRiders.length > 0 ? (
                                filteredRiders.map((rider, index) => (
                                    <RiderRow
                                        key={rider.URN}
                                        user={rider}
                                        controlPoints={controlPoints}
                                        handleControlClick={handleControlClick}
                                        handleStatusChange={handleStatusChange}
                                        handleClearResult={handleClearResult}
                                        index={index}
                                        showToast={showToast}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="px-6 py-8 text-center text-gray-500">
                                        <div className="flex flex-col items-center space-y-2">
                                            <Users className="h-8 w-8" />
                                            <p>No riders found matching the current filters</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default RidersTable;
