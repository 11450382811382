import React from 'react';
import { Link } from 'react-router-dom';

const InfoCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
      <div
        data-aos="fade-up"
        className="bg-white/80 backdrop-blur-md rounded-lg shadow-md p-6 text-center transition-transform hover:scale-105"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Find Rides</h2>
        <p className="text-gray-600 mb-6">
          Discover new and exciting rides in your area.
        </p>
        <Link
          to="/rides"
          className="bg-indigo-600 text-white font-semibold py-2 px-4 rounded-full hover:bg-indigo-700 transition"
        >
          Browse Rides
        </Link>
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="200"
        className="bg-white/80 backdrop-blur-md rounded-lg shadow-md p-6 text-center transition-transform hover:scale-105"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Join a Community</h2>
        <p className="text-gray-600 mb-6">
          Connect with other cyclists and share your passion.
        </p>
        <Link
          to="/community"
          className="bg-indigo-600 text-white font-semibold py-2 px-4 rounded-full hover:bg-indigo-700 transition"
        >
          Join Now
        </Link>
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        className="bg-white/80 backdrop-blur-md rounded-lg shadow-md p-6 text-center transition-transform hover:scale-105"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Track Your Progress</h2>
        <p className="text-gray-600 mb-6">
          Monitor your performance and achieve your goals.
        </p>
        <Link
          to="/progress"
          className="bg-indigo-600 text-white font-semibold py-2 px-4 rounded-full hover:bg-indigo-700 transition"
        >
          Track Now
        </Link>
      </div>
    </div>
  );
};

export default InfoCards;