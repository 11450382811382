import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import { statesAndCities } from '../../config/statesAndCities';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEventPage = () => {
  const { state } = useAuth();
  const navigate = useNavigate();

  // Basic event info
  const [title, setTitle] = useState('');
  // We'll store the description as HTML from the Quill editor
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');

  // Always default to India
  const country = 'India';

  // State and city
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  // Event type fields
  const [eventType, setEventType] = useState('');
  const [website, setWebsite] = useState('');

  // A unified array for standard distances (Running, Triathlon, Cycling, etc.)
  const [standardDistances, setStandardDistances] = useState([]);

  // For demonstration, define possible standard distances per type
  const runningDistancesList = ['5K', '10K', '21K (Half Marathon)', '42K (Marathon)'];
  const triathlonDistancesList = ['Sprint', 'Olympic', 'Half Ironman', 'Full Ironman'];
  const cyclingDistancesList = ['50K', '100K', '200K'];

  // NEW: Array of custom distances
  const [customDistances, setCustomDistances] = useState([]);

  const [errorMsg, setErrorMsg] = useState('');

  // Quill modules for text formatting
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  useEffect(() => {
    // If user is not authenticated, redirect to login
    if (!state.isAuthenticated) {
      navigate('/login?redirect=/events/new');
    }
  }, [state.isAuthenticated, navigate]);

  // Handle toggling a standard distance in our unified array
  const handleStandardDistanceChange = (dist) => {
    setStandardDistances((prev) =>
      prev.includes(dist) ? prev.filter((d) => d !== dist) : [...prev, dist]
    );
  };

  // Handlers for custom distances
  const handleAddCustomDistance = () => {
    setCustomDistances((prev) => [...prev, '']);
  };

  const handleRemoveCustomDistance = (index) => {
    setCustomDistances((prev) => prev.filter((_, i) => i !== index));
  };

  const handleCustomDistanceChange = (index, value) => {
    setCustomDistances((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      // Construct request body with the new structure
      const body = {
        OwnerUserId: state.idTokenPayload?.sub,
        title,
        description, // store HTML content
        location,
        date,
        country,
        state: selectedState,
        city: selectedCity,
        eventType,
        website,
        standardDistances,
        customDistances: customDistances
          .map((cd) => parseFloat(cd))
          .filter((num) => !isNaN(num) && num > 0),
      };

      await axios.post('/events', body, {
        headers: {
          Authorization: `Bearer ${state.idToken}`,
        },
      });

      // On success, go to /events
      navigate('/events');
    } catch (error) {
      console.error('Error creating event:', error);
      if (error.response) {
        setErrorMsg(
          `Event creation failed (${error.response.status}): ${
            error.response.data?.message || JSON.stringify(error.response.data)
          }`
        );
      } else {
        setErrorMsg(`Event creation failed: ${error.message}`);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-2xl mx-auto bg-white p-6 rounded shadow">
        <h1 className="text-2xl font-bold text-primary mb-4">Add a New Event</h1>

        {errorMsg && <p className="text-red-600 mb-4">{errorMsg}</p>}

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Title */}
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="title">
              Event Title
            </label>
            <input
              type="text"
              id="title"
              required
              className="w-full px-3 py-2 border rounded"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          {/* Description with Quill */}
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="description">
              Description
            </label>
            <ReactQuill
              id="description"
              value={description}
              onChange={setDescription}
              modules={modules}
              theme="snow"
              className="mb-2"
            />
            {/* Preview */}
            <div className="mt-4 p-4 border rounded bg-gray-50">
              <h2 className="text-lg font-semibold mb-2">Preview</h2>
              <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>

          {/* Location */}
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="location">
              Location (Address/Venue)
            </label>
            <input
              type="text"
              id="location"
              className="w-full px-3 py-2 border rounded"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          {/* Date */}
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="date">
              Date
            </label>
            <input
              type="date"
              id="date"
              className="w-full px-3 py-2 border rounded"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          {/* State */}
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="state">
              State/UT
            </label>
            <select
              id="state"
              className="w-full px-3 py-2 border rounded"
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value);
                setSelectedCity('');
              }}
            >
              <option value="">-- Select a State/UT --</option>
              {Object.keys(statesAndCities).map((stateName) => (
                <option key={stateName} value={stateName}>
                  {stateName}
                </option>
              ))}
            </select>
          </div>

          {/* City */}
          {selectedState && statesAndCities[selectedState] && (
            <div>
              <label className="block text-gray-700 mb-1" htmlFor="city">
                City
              </label>
              <select
                id="city"
                className="w-full px-3 py-2 border rounded"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                <option value="">-- Select a City --</option>
                {statesAndCities[selectedState].map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Event Type */}
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="eventType">
              Event Type
            </label>
            <select
              id="eventType"
              required
              className="w-full px-3 py-2 border rounded"
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
                setStandardDistances([]);
                setCustomDistances([]);
              }}
            >
              <option value="">-- Select an event type --</option>
              <option value="Running">Running</option>
              <option value="Cycling">Cycling</option>
              <option value="Triathlon">Triathlon</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Standard Distances */}
          {eventType === 'Running' && (
            <div>
              <p className="block text-gray-700 mb-1">
                Standard Running Distances (select all that apply):
              </p>
              {runningDistancesList.map((dist) => (
                <label key={dist} className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={standardDistances.includes(dist)}
                    onChange={() => handleStandardDistanceChange(dist)}
                  />
                  {dist}
                </label>
              ))}
            </div>
          )}

          {eventType === 'Triathlon' && (
            <div>
              <p className="block text-gray-700 mb-1">
                Standard Triathlon Distances (select all that apply):
              </p>
              {triathlonDistancesList.map((dist) => (
                <label key={dist} className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={standardDistances.includes(dist)}
                    onChange={() => handleStandardDistanceChange(dist)}
                  />
                  {dist}
                </label>
              ))}
            </div>
          )}

          {eventType === 'Cycling' && (
            <div>
              <p className="block text-gray-700 mb-1">
                Standard Cycling Distances (select all that apply):
              </p>
              {cyclingDistancesList.map((dist) => (
                <label key={dist} className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={standardDistances.includes(dist)}
                    onChange={() => handleStandardDistanceChange(dist)}
                  />
                  {dist}
                </label>
              ))}
            </div>
          )}

          {/* Custom Distances */}
          <div>
            <p className="block text-gray-700 mb-1">Custom Distances (km)</p>
            {customDistances.map((cd, index) => (
              <div key={index} className="flex items-center space-x-2 mb-2">
                <input
                  type="number"
                  className="w-24 px-3 py-2 border rounded"
                  value={cd}
                  onChange={(e) => handleCustomDistanceChange(index, e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveCustomDistance(index)}
                  className="px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddCustomDistance}
              className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
            >
              + Add Custom Distance
            </button>
          </div>

          {/* Website */}
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="website">
              Organizer's Website URL
            </label>
            <input
              type="url"
              id="website"
              className="w-full px-3 py-2 border rounded"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>

          {/* Submit */}
          <button
            type="submit"
            className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark transition"
          >
            Submit Event
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEventPage;