import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';

const HeroSection = () => {
  const { state } = useAuth();

  return (
    <div
      className="relative w-full h-[90vh] flex flex-col items-center justify-center text-center text-white overflow-hidden"
      style={{
        backgroundImage: 'url(/background.jpg)',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      {/* Gradient Overlay: ensure it doesn't block pointer events */}
      <div
        className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-500 opacity-60 pointer-events-none"
      ></div>

      {/* Floating Shapes (also ensure no pointer events) */}
      <div
        className="absolute top-10 left-10 w-32 h-32 bg-white/20 rounded-full blur-xl animate-pulse pointer-events-none"
      ></div>
      <div
        className="absolute bottom-20 right-10 w-24 h-24 bg-white/10 rounded-full blur-xl animate-ping pointer-events-none"
      ></div>

      <div className="relative z-10 px-4 flex flex-col items-center">
        <h1 className="text-5xl md:text-6xl font-extrabold mb-4 drop-shadow-md">
          Welcome to UltraRides
        </h1>
        <p className="text-lg md:text-2xl mb-2 font-medium drop-shadow-sm">
          Where Every Ride Counts
        </p>
        <p className="max-w-2xl text-sm md:text-lg text-gray-100 mb-8 drop-shadow-sm">
          UltraRides is your ultimate platform for discovering epic cycling events,
          connecting with local clubs, and celebrating your achievements with homologations
          and digital certificates. Join us to elevate your cycling experience and become
          part of our vibrant community.
        </p>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          {state.isAuthenticated ? (
            <Link
              to="/dashboard"
              className="inline-flex items-center justify-center bg-white/80 text-gray-800 py-3 px-8 rounded-full font-semibold text-xl hover:bg-white shadow-xl transition-all"
            >
              Go to your Dashboard
              <FaArrowRight className="ml-2" />
            </Link>
          ) : (
            <>
              <Link
                to="/create-account"
                className="inline-flex items-center justify-center bg-white/80 text-gray-800 py-3 px-8 rounded-full font-semibold text-xl hover:bg-white shadow-xl transition-all"
              >
                Join as a Club Owner
                <FaArrowRight className="ml-2" />
              </Link>
              <Link
                to="/create-account"
                className="inline-flex items-center justify-center bg-white/80 text-gray-800 py-3 px-8 rounded-full font-semibold text-xl hover:bg-white shadow-xl transition-all"
              >
                Join as a Rider
                <FaArrowRight className="ml-2" />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;