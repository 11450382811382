import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import AdminLayout from './AdminLayout';
import AdminAccessCheck from './AdminAccessCheck';
import {
    FaUser, FaUsers, FaUserPlus, FaCreditCard, FaInfoCircle, FaEdit,
    FaMapMarkerAlt, FaEye, FaRoute, FaTags, FaUserTag,
    FaMoneyBillWave, FaStopwatch, FaChevronDown, FaChevronRight,
    FaImage, FaFileAlt, FaBook, FaChartLine, FaClipboardList,
    FaPoll, FaFlag
} from 'react-icons/fa';
import { AlertTriangle } from 'lucide-react';

function AdminCard({ icon, title, description, to, className = '', disabled = false }) {
    const cardClasses = `bg-white rounded p-3 ${disabled
        ? 'opacity-50 cursor-not-allowed'
        : 'hover:shadow-md transition-all duration-200'
        } border border-secondary hover:border-accent ${className}`;

    const content = (
        <>
            <div className="flex items-center gap-2 mb-1">
                <div className="p-1.5 bg-background rounded">
                    {React.cloneElement(icon, { className: 'w-4 h-4' })}
                </div>
                <h3 className="text-sm font-medium text-textPrimary">{title}</h3>
            </div>
            <p className="text-xs text-textSecondary line-clamp-2">{description}</p>
        </>
    );

    return disabled ? (
        <div className={cardClasses}>{content}</div>
    ) : (
        <Link to={to} className={cardClasses}>{content}</Link>
    );
}

function AdminSection({ title, subtitle = '', children }) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <div className="border border-gray-200 rounded shadow-sm overflow-hidden">
            <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="w-full flex items-center justify-between p-2 bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
            >
                <div>
                    <h2 className="text-base font-medium text-gray-800 flex items-center">
                        {isCollapsed ? <FaChevronRight className="w-3 h-3 mr-2" /> : <FaChevronDown className="w-3 h-3 mr-2" />}
                        {title}
                    </h2>
                    {subtitle && <p className="text-xs text-gray-600">{subtitle}</p>}
                </div>
            </button>

            <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'h-0' : 'h-auto'}`}>
                <div className={`p-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 ${isCollapsed ? 'hidden' : 'block'}`}>
                    {children}
                </div>
            </div>
        </div>
    );
}

function MasterAdminPage() {
    const location = useLocation();
    const { rideId } = useParams();
    const [isClubOwner, setIsClubOwner] = React.useState(false);

    const { data: ride, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
        staleTime: 1000 * 60 * 5,
    });

    React.useEffect(() => {
        if (ride?.user) {
            setIsClubOwner(ride.user.isClubOwner);
        }
    }, [ride]);

    const isRaceEvent = ride?.timeControlType === 'race';
    const hasStarted = new Date(ride?.startDateTime) <= new Date();

    if (isLoading) return <div>Loading...</div>;

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Event Administration">
                <div className="space-y-2">
                    {isRaceEvent ? (
                        <AdminSection
                            title="Race Management"
                            subtitle="Manage race results, rankings, and tracking"
                        >
                            <AdminCard
                                icon={<FaUserPlus />}
                                title="Register Rider"
                                description="Add new rider to the race"
                                to={`${location.pathname}/register-race`}
                            />
                            <AdminCard
                                icon={<FaStopwatch />}
                                title="Race Results"
                                description="Record and manage race timings and results"
                                to={`${location.pathname}/race-results`}
                            />
                            <AdminCard
                                icon={<FaTags />}
                                title="Race Categories"
                                description="Manage race categories and classifications"
                                to={`${location.pathname}/categories`}
                            />
                            <AdminCard
                                icon={<FaUserTag />}
                                title="Rider Categories"
                                description="Assign and manage rider categories"
                                to={`${location.pathname}/rider-categories`}
                            />
                            <AdminCard
                                icon={<FaFlag />}
                                title="Start/Finish Lines"
                                description="Manage start and finish line configurations"
                                to={`${location.pathname}/start-finish-lines`}
                            />
                        </AdminSection>
                    ) : (
                        <AdminSection
                            title="Audax Event Management"
                            subtitle="Monitor progress and validate completions"
                        >
                            <AdminCard
                                icon={<FaChartLine />}
                                title="Live Rider Progress"
                                description="Monitor live rider progress at control points"
                                to={`${location.pathname}/live-riders`}
                            />
                            <AdminCard
                                icon={<AlertTriangle />}
                                title="Missing CP Monitor"
                                description="Track riders with missing control points"
                                to={`${location.pathname}/missing-cp`}
                            />
                            <AdminCard
                                icon={<FaPoll />}
                                title="Results Management"
                                description="Manage rider results and completions"
                                to={`${location.pathname}/riders`}
                            />
                        </AdminSection>
                    )}

                    <AdminSection
                        title="Event Settings"
                        subtitle="Configure event details and visibility"
                    >
                        <AdminCard
                            icon={<FaClipboardList />}
                            title="Registration Details"
                            description="View and manage registration information"
                            to={`${location.pathname}/registration`}
                        />
                        <AdminCard
                            icon={<FaInfoCircle />}
                            title="Event Details"
                            description="Edit event information and schedule"
                            to={`${location.pathname}/ride-information`}
                        />
                        <AdminCard
                            icon={<FaEye />}
                            title="Event Visibility"
                            description="Control event visibility and access"
                            to={`${location.pathname}/visibility`}
                        />
                        <AdminCard
                            icon={<FaUser />}
                            title="Responsible Person"
                            description="Edit responsible person details"
                            to={`${location.pathname}/responsible-person`}
                        />
                    </AdminSection>

                    <AdminSection
                        title="Content Management"
                        subtitle="Manage event content and media"
                    >
                        <AdminCard
                            icon={<FaImage />}
                            title="Cover Image"
                            description="Manage event cover image"
                            to={`${location.pathname}/cover-image`}
                        />
                        <AdminCard
                            icon={<FaFileAlt />}
                            title="Event Description"
                            description="Edit event description and content"
                            to={`${location.pathname}/details-editor`}
                        />
                        <AdminCard
                            icon={<FaBook />}
                            title="Rules and Regulations"
                            description="Edit event rules and regulations"
                            to={`${location.pathname}/rules-and-regulations`}
                        />
                    </AdminSection>

                    <AdminSection
                        title="Route & Control Points"
                        subtitle="Manage route, control points and payments"
                    >
                        <AdminCard
                            icon={<FaRoute />}
                            title="Route Description"
                            description="Edit route description and navigation"
                            to={`${location.pathname}/route-description`}
                        />
                        <AdminCard
                            icon={<FaMapMarkerAlt />}
                            title="Control Points"
                            description="Manage control points and timing stations"
                            to={`${location.pathname}/control-points`}
                        />
                        <AdminCard
                            icon={<FaCreditCard />}
                            title="Payment Settings"
                            description="Configure event fees and payment options"
                            to={`${location.pathname}/ride-payment`}
                        />
                        <AdminCard
                            icon={<FaMoneyBillWave />}
                            title="Offline Payments"
                            description="Manage offline payment records"
                            to={`${location.pathname}/offline-payments`}
                        />
                        <AdminCard
                            icon={<FaMoneyBillWave />}
                            title="Payment History"
                            description="View and manage payment transactions"
                            to={`${location.pathname}/payment-history`}
                        />
                    </AdminSection>
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default MasterAdminPage;
