import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaSignOutAlt, FaUserCircle, FaCalendarAlt, FaTachometerAlt, FaUsers, FaBook } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const { state, logout } = useAuth();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-primary p-4">
      <div className="container mx-auto flex items-center justify-between flex-wrap">
        <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-0 lg:ml-6">
          <Link
            id="nav-home-logo"
            to="/"
            className="flex items-center font-semibold text-xl tracking-tight text-white mx-auto lg:mx-0"
          >
            <img
              src="/logo3.png"
              alt="Ultra-Rides Logo"
              className="h-auto w-auto max-h-20 max-w-40 mr-4"
            />
          </Link>
        </div>

        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-gray-200 hover:border-gray-200"
          >
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? '' : 'hidden'}`}>
          <div className="text-sm lg:flex-grow">
            <Link
              id="nav-rides-calendar"
              to="/rides"
              className="block mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 flex items-center lg:text-base ml-4"
            >
              <FaCalendarAlt className="mr-2 text-lg lg:text-xl" />
              Rides
            </Link>

            <Link
              id="nav-clubs"
              to="/clubs"
              className="block mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 flex items-center lg:text-base ml-4"
            >
              <FaUsers className="mr-2 text-lg lg:text-xl" />
              Clubs
            </Link>

            {/* New Link for "Events" */}
            <Link
              id="nav-events"
              to="/events"
              className="block mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 flex items-center lg:text-base ml-4"
            >
              <FaCalendarAlt className="mr-2 text-lg lg:text-xl" />
              Events
            </Link>
          </div>

          <div className="text-sm lg:flex items-center">
            {!state.isAuthenticated ? (
              <>
                <Link
                  id="nav-login"
                  to="/login"
                  className="flex items-center mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 lg:text-base ml-4"
                >
                  <FaSignOutAlt className="mr-2 text-lg lg:text-xl" />
                  Login
                </Link>

                <Link
                  id="nav-signup"
                  to="/create-account"
                  className="flex items-center mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 lg:text-base ml-4"
                >
                  <FaUserCircle className="mr-2 text-lg lg:text-xl" />
                  Sign Up
                </Link>
                <Link
                  id="nav-docs"
                  to="https://docs.ultra-rides.com"
                  className="flex items-center mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 lg:text-base ml-4"
                >
                  <FaBook className="mr-2 text-lg lg:text-xl" />
                  Docs
                </Link>
              </>
            ) : (
              <>
                <Link
                  id="nav-dashboard"
                  to="/dashboard"
                  className="flex items-center mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 lg:text-base ml-4"
                >
                  <FaTachometerAlt className="mr-2 text-lg lg:text-xl" />
                  Dashboard
                </Link>
                <Link
                  id="nav-docs"
                  to="https://docs.ultra-rides.com"
                  className="flex items-center mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 mr-4 lg:text-base ml-4"
                >
                  <FaBook className="mr-2 text-lg lg:text-xl" />
                  Docs
                </Link>
                <button
                  id="nav-logout"
                  onClick={logout}
                  className="flex items-center mt-4 lg:inline-block lg:mt-0 text-white font-light hover:text-gray-200 lg:text-base ml-4"
                >
                  <FaSignOutAlt className="mr-2 text-lg lg:text-xl" />
                  Logout
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;