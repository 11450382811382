import React from "react";
import axios from "../../config/axiosConfig";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";

/**
 * A more generic approach to track user status for an event:
 * NONE, INTERESTED, or GOING.
 *
 * Endpoints assumed:
 * GET /events/:eventId/userStatus
 *   -> { userStatus: "NONE"|"INTERESTED"|"GOING",
 *        interestedCount: number,
 *        goingCount: number }
 *
 * POST /events/:eventId/userStatus
 *   -> expects { status: "NONE"|"INTERESTED"|"GOING" }
 *   -> sets the user's status for this event
 */

async function fetchEventStatus(eventId, token) {
  const response = await axios.get(`/events/${eventId}/userStatus`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function setEventStatus({ eventId, status, token }) {
  // We'll call POST to set current user's status
  const response = await axios.post(
    `/events/${eventId}/userStatus`,
    { status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

const EventInterestButton = ({ eventId }) => {
  const { state } = useAuth();
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["eventStatus", eventId],
    queryFn: () => fetchEventStatus(eventId, state.idToken),
    enabled: !!(state.isAuthenticated && eventId),
  });

  const setStatusMutation = useMutation({
    mutationFn: (vars) => setEventStatus(vars),
    onSuccess: () => {
      // Refetch status info
      queryClient.invalidateQueries(["eventStatus", eventId]);
    },
  });

  const handleSetStatus = (newStatus) => {
    if (!state.isAuthenticated) return;
    setStatusMutation.mutate({
      eventId,
      status: newStatus,
      token: state.idToken,
    });
  };

  if (!state.isAuthenticated) {
    // If user is not logged in, show nothing or a simple note
    return null;
  }

  if (isLoading) {
    return <p className="mt-4 text-sm text-gray-500">Loading event status...</p>;
  }

  if (isError) {
    return (
      <p className="mt-4 text-sm text-red-600">
        {error?.response
          ? `Error (${error.response.status}): ${
              error.response.data?.message || JSON.stringify(error.response.data)
            }`
          : `Error: ${error?.message || "Failed to load status"}`}
      </p>
    );
  }

  const { userStatus, interestedCount, goingCount } = data || {};

  /**
   * Improved UI/UX with a more polished container:
   *  - Subtle shadow
   *  - Spacing and headings
   *  - Modern Tailwind classes for buttons
   *  - Clear visual distinction for statuses
   */
  return (
    <div className="mt-8 max-w-md rounded-lg shadow-sm border border-gray-200 bg-white p-4 sm:p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-base font-semibold text-gray-800">Attend Status</h2>
        <div className="text-sm text-gray-600 flex flex-col items-end space-y-0.5">
          <span>Interested: <strong>{interestedCount || 0}</strong></span>
          <span>Going: <strong>{goingCount || 0}</strong></span>
        </div>
      </div>
      <hr className="mb-4" />

      {/* Buttons or UI logic for the three statuses. */}
      {userStatus === "NONE" && (
        <div className="flex flex-col sm:flex-row gap-3">
          <button
            onClick={() => handleSetStatus("INTERESTED")}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            disabled={setStatusMutation.isLoading}
          >
            {setStatusMutation.isLoading ? "Processing..." : "Mark as Interested"}
          </button>
          <button
            onClick={() => handleSetStatus("GOING")}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors"
            disabled={setStatusMutation.isLoading}
          >
            {setStatusMutation.isLoading ? "Processing..." : "Mark as Going"}
          </button>
        </div>
      )}

      {userStatus === "INTERESTED" && (
        <div className="flex flex-col sm:flex-row gap-3">
          <span className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 border border-blue-200 bg-blue-50 rounded-md shadow-sm select-none">
            You are Interested
          </span>
          <button
            onClick={() => handleSetStatus("GOING")}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors"
            disabled={setStatusMutation.isLoading}
          >
            {setStatusMutation.isLoading ? "Processing..." : "Switch to Going"}
          </button>
          <button
            onClick={() => handleSetStatus("NONE")}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors"
            disabled={setStatusMutation.isLoading}
          >
            Remove
          </button>
        </div>
      )}

      {userStatus === "GOING" && (
        <div className="flex flex-col sm:flex-row gap-3">
          <span className="inline-flex items-center px-4 py-2 text-sm font-medium text-green-700 border border-green-200 bg-green-50 rounded-md shadow-sm select-none">
            You are Going
          </span>
          <button
            onClick={() => handleSetStatus("INTERESTED")}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            disabled={setStatusMutation.isLoading}
          >
            {setStatusMutation.isLoading ? "Processing..." : "Switch to Interested"}
          </button>
          <button
            onClick={() => handleSetStatus("NONE")}
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors"
            disabled={setStatusMutation.isLoading}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default EventInterestButton;