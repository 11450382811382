import React from 'react';

const RefundPolicy = () => {
    return (
        <div className="min-h-screen bg-gray-100 p-8">
            <div className="bg-white max-w-4xl mx-auto p-6 rounded-lg shadow-lg">
                <h1 className="text-4xl font-bold text-center text-primary mb-4">Refund Policy</h1>
                <p className="text-lg text-gray-700 mb-6">
                    Thank you for choosing Ultra-Rides. We want you to be fully satisfied with your event registrations and any related services. This Refund Policy outlines our general guidelines and procedures regarding refunds.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Event Registrations</h2>
                <p className="text-gray-600 mb-4">
                    If you need to cancel your registration for an upcoming ride or event, please contact our support team as soon as possible. Refund eligibility and the amount refunded may depend on the cancellation date relative to the event start date. Certain administrative or third-party fees may be non-refundable.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Merchandise or Other Purchases</h2>
                <p className="text-gray-600 mb-4">
                    For products purchased through Ultra-Rides (e.g., cycling gear or accessories), refunds or exchanges may be offered if the items are returned in new, unused condition within a specified time frame. Please consult product-specific return policies or reach out to our support team for assistance.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Refund Process</h2>
                <p className="text-gray-600 mb-4">
                    Refunds, when approved, will be processed using the original payment method. Processing times may vary depending on your bank or credit card issuer.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Non-Refundable Fees</h2>
                <p className="text-gray-600 mb-4">
                    Some fees, such as service fees, transaction costs, or third-party platform charges, may be non-refundable. Please review all terms carefully before finalizing your purchase.
                </p>
                <h2 className="text-2xl font-semibold text-primary mb-2">Contact Us</h2>
                <p className="text-gray-600 mb-4">
                    For questions or concerns regarding refunds, cancellations, or exchanges, please contact us at support@ultra-rides.com. We are here to help ensure a fair experience for all riders.
                </p>
            </div>
        </div>
    );
};

export default RefundPolicy;