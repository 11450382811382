import React, { useState, useEffect } from 'react';
import { Legend } from './';
import { CategoryFilter } from './';
import { PaginationButtons } from './';
import { RidersTable } from './';
import { Search } from 'lucide-react';

function RegisteredRidersTab({ registeredRiders, payment, categories, defaultCategoryId, timeControlType }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sortField, setSortField] = useState('rank');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const ridersPerPage = 25;

  // Filter riders by category and search query
  const filteredRiders = registeredRiders
    .filter(rider => {
      const matchesCategory = selectedCategory ? rider.categoryId === selectedCategory : true;
      const matchesSearch = searchQuery.trim() === '' ? true : 
        rider.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        rider.urn.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesCategory && matchesSearch;
    });

  // Sort riders by selected field
  const sortedRiders = [...filteredRiders].sort((a, b) => {
    if (sortField === 'name') {
      return sortOrder === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortField === 'rank') {
      const rankA = a.rank || Number.MAX_SAFE_INTEGER;
      const rankB = b.rank || Number.MAX_SAFE_INTEGER;
      return sortOrder === 'asc'
        ? rankA - rankB
        : rankB - rankA;
    }
    return 0;
  });

  // Get current riders for pagination
  const indexOfLastRider = currentPage * ridersPerPage;
  const indexOfFirstRider = indexOfLastRider - ridersPerPage;
  const currentRiders = sortedRiders.slice(indexOfFirstRider, indexOfLastRider);
  const totalPages = Math.ceil(sortedRiders.length / ridersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCategoryCount = (categoryId) => {
    return registeredRiders.filter(rider => rider.categoryId === categoryId).length;
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory, searchQuery]);

  return (
    <div className="space-y-6">
      {/* Category Filter */}
      {categories?.length > 0 && (
        <CategoryFilter
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          getCategoryCount={getCategoryCount}
          registeredRiders={registeredRiders}
        />
      )}

      {/* Table Controls Section */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          {/* Search Input */}
          <div className="relative flex-1 max-w-md w-full">
            <input
              type="text"
              placeholder="Search by name or URN..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary/50 focus:border-primary transition-colors"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          </div>
          
          {/* Results Count */}
          <div className="text-sm text-gray-500 whitespace-nowrap">
            {searchQuery ? (
              <span>Found {filteredRiders.length} rider(s)</span>
            ) : (
              <span>Total {registeredRiders.length} rider(s)</span>
            )}
          </div>
        </div>
      </div>
      
      {/* Pagination */}
      {totalPages > 1 && (
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          position="top"
        />
      )}
      
      {/* Riders Table */}
      <RidersTable
        currentRiders={currentRiders}
        currentPage={currentPage}
        ridersPerPage={ridersPerPage}
        payment={payment}
        timeControlType={timeControlType}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
      />
      
      <Legend />
      
      {/* Bottom Pagination */}
      {totalPages > 1 && (
        <PaginationButtons
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          position="bottom"
        />
      )}
    </div>
  );
}

export default RegisteredRidersTab;
