import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';
import LiveRidersControl from '../../RideAdmin_ResultManagement/AdminRidersListComponents/LiveRidersControl';
import AdminAccessCheck from '../AdminAccessCheck';
import { Users, Loader2 } from 'lucide-react';

function LiveRidersInfo() {
    const { rideId } = useParams();
    console.log('LiveRidersInfo - Starting with rideId:', rideId);

    // First fetch ride details
    const { data: rideDetails, isLoading: rideLoading } = useQuery({
        queryKey: ['rideDetail', rideId],
        queryFn: async () => {
            console.log('Fetching ride details...');
            const response = await axios.get(`/rides/${rideId}`);
            console.log('Ride details response:', response.data);
            return response.data;
        },
    });

    // Then fetch check-ins data
    const { data: checkinsData, isLoading: checkinsLoading } = useQuery({
        queryKey: ['allCheckins', rideId],
        queryFn: async () => {
            console.log('Fetching check-ins...');
            const response = await axios.get(`/rides/${rideId}/all-checkins`);
            console.log('Check-ins response:', response.data);
            return response.data;
        },
        enabled: !!rideDetails, // Only fetch check-ins after ride details are available
    });

    const [isClubOwner, setIsClubOwner] = React.useState(false);

    React.useEffect(() => {
        if (rideDetails?.user) {
            setIsClubOwner(rideDetails.user.isClubOwner);
        }
    }, [rideDetails]);

    console.log('Current data state:', {
        rideDetails: !!rideDetails,
        checkinsData: !!checkinsData,
        controlPoints: rideDetails?.controlPoints,
        totalCheckins: checkinsData?.length
    });

    if (rideLoading || checkinsLoading) {
        return (
            <AdminLayout title="Live Riders Information">
                <div className="flex flex-col items-center justify-center py-12">
                    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                    <p className="mt-4 text-gray-600">Loading ride information...</p>
                </div>
            </AdminLayout>
        );
    }

    if (!rideDetails || !checkinsData) {
        return (
            <AdminLayout title="Live Riders Information">
                <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
                    <p className="text-yellow-700">No ride data available.</p>
                </div>
            </AdminLayout>
        );
    }

    const activeRiders = checkinsData.filter(rider =>
        rider.ControlPoints?.length > 0 && !rider.Result
    );

    const finishedRiders = checkinsData.filter(rider =>
        rider.Result === 'Finished'
    );

    return (
        <AdminAccessCheck isAdmin={isClubOwner}>
            <AdminLayout title="Live Riders Information">
                <div className="space-y-6">
                    {/* Stats Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="bg-white p-6 rounded-lg shadow-sm">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm text-gray-600">Total Registered</p>
                                    <p className="text-2xl font-bold text-gray-900">{checkinsData.length}</p>
                                </div>
                                <Users className="h-8 w-8 text-blue-500" />
                            </div>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-sm">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm text-gray-600">Currently Active</p>
                                    <p className="text-2xl font-bold text-gray-900">{activeRiders.length}</p>
                                </div>
                                <Users className="h-8 w-8 text-green-500" />
                            </div>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-sm">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="text-sm text-gray-600">Finished</p>
                                    <p className="text-2xl font-bold text-gray-900">{finishedRiders.length}</p>
                                </div>
                                <Users className="h-8 w-8 text-purple-500" />
                            </div>
                        </div>
                    </div>

                    {/* Debug Info - Remove in production */}
                    <div className="bg-gray-50 p-4 rounded-lg text-sm text-gray-600">
                        <p>Control Points: {rideDetails.controlPoints?.length || 0}</p>
                        <p>Total Checkins: {checkinsData.length}</p>
                    </div>

                    {/* Control Points Status */}
                    <LiveRidersControl
                        riders={checkinsData}
                        controlPoints={rideDetails.controlPoints || []}
                    />
                </div>
            </AdminLayout>
        </AdminAccessCheck>
    );
}

export default LiveRidersInfo;
