import React from 'react';
import { FaUserPlus, FaSearch, FaMapMarkedAlt, FaMedal, FaUsers, FaUsersCog, FaMobile, FaUserCircle } from 'react-icons/fa';

const sections = [
  {
    title: "Sign Up & Get Started",
    icon: <FaUserPlus />,
    items: ["Create account", "Get unique URN", "Complete profile", "Access via web/mobile"]
  },
  {
    title: "Find Your Ride",
    icon: <FaSearch />,
    items: ["Browse curated rides", "Multiple ride types", "View route details", "Register directly"]
  },
  {
    title: "Digital Control Points",
    icon: <FaMapMarkedAlt />,
    items: ["Check in via app", "Photo verification", "Location tracking", "Real-time progress"]
  },
  {
    title: "Validation & Recognition",
    icon: <FaMedal />,
    items: ["Automatic homologation", "Earn certificates", "Collect badges", "Track achievements"]
  },
  {
    title: "Community Features",
    icon: <FaUsers />,
    items: ["Join clubs", "View leaderboards", "Track statistics", "Share achievements"]
  },
  {
    title: "For Organizers",
    icon: <FaUsersCog />,
    items: ["Manage rides", "Set control points", "Monitor progress", "Issue certificates"]
  },
  {
    title: "Supported Platforms",
    icon: <FaMobile />,
    items: ["Web application", "iOS & Android apps", "Real-time sync", "Cross-platform access"]
  },
  {
    title: "Profile System",
    icon: <FaUserCircle />,
    items: ["Public profiles", "Achievement showcase", "Ride history", "Level progression"]
  }
];

const HowItWorks = () => {
  return (
    <div className="py-16 px-4 md:px-8 bg-gradient-to-b from-gray-50 to-white">
      <h2
        className="text-4xl font-extrabold text-center mb-12 text-gray-800"
        data-aos="fade-up"
      >
        How It Works
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
        {sections.map((section, index) => (
          <div
            key={section.title}
            data-aos="fade-up"
            data-aos-delay={index * 100}
            className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
          >
            <div className="flex items-center mb-4">
              <div className="p-3 bg-indigo-100 rounded-lg text-indigo-600 mr-4">
                {section.icon}
              </div>
              <h3 className="text-xl font-bold text-gray-800">{section.title}</h3>
            </div>
            
            <ul className="space-y-2">
              {section.items.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  className="flex items-center text-gray-600"
                >
                  <span className="w-2 h-2 bg-indigo-400 rounded-full mr-2"></span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div
        className="mt-12 text-center"
        data-aos="fade-up"
        data-aos-delay="800"
      >
        <p className="text-gray-600 max-w-2xl mx-auto">
          Join thousands of cyclists on Ultra-Rides and start tracking your achievements today.
          Our digital platform makes ride validation and certification easier than ever.
        </p>
      </div>
    </div>
  );
};

export default HowItWorks;