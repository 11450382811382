import React from "react";
import { Link } from "react-router-dom";
import { getVersionString } from "../lib/config/version";

const Footer = () => {
  return (
    <footer className="bg-background-light text-primary-dark py-4 border-t border-primary">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="flex flex-col items-center md:items-start">
          <p className="text-primary-dark text-lg font-medium mb-2">&copy; 2025 Ultra-Rides</p>
          <p className="text-primary-dark text-sm">All rights reserved.</p>
          <p className="text-primary-dark text-xs mt-2">{getVersionString()}</p>
        </div>
        <div className="flex flex-col items-center md:items-center">
          <nav className="flex flex-col items-center mb-2 space-y-1">
            <Link to="/privacy-policy" className="text-primary-dark hover:text-secondary text-sm">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="text-primary-dark hover:text-secondary text-sm">
              Terms of Service
            </Link>
            <Link to="/faq" className="text-primary-dark hover:text-secondary text-sm">
              FAQ
            </Link>
            <Link to="/rules-and-regulations" className="text-primary-dark hover:text-secondary text-sm">
              Rules &amp; Regulations
            </Link>
            <Link to="/refund-policy" className="text-primary-dark hover:text-secondary text-sm">
              Refund Policy
            </Link>
          </nav>
        </div>
        <div className="flex flex-col items-center md:items-end">
          <nav className="flex flex-col items-center md:items-end mb-2 space-y-1">
            <Link to="/contact" className="text-primary-dark hover:text-secondary text-sm">
              Contact Us
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;