import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import ErrorMessage from '../Message';
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction
} from '../ui/alert-dialog';

const fetchUserEvents = async (token) => {
  const response = await axios.get('/events/user', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const MyEvents = () => {
  const { state } = useAuth();
  const navigate = useNavigate();
  // Create an object to store errors for each event
  const [eventErrors, setEventErrors] = useState({});

  const {
    data: myEvents,
    error,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['myEvents'],
    queryFn: () => fetchUserEvents(state.idToken),
    enabled: state.isAuthenticated,
    staleTime: 1000 * 60 * 5,
  });

  const handleDelete = async (eventID) => {
    // Clear any existing error for this event
    setEventErrors(prev => ({ ...prev, [eventID]: '' }));
    
    try {
      await axios.delete(`/events/${eventID}`, {
        headers: {
          Authorization: `Bearer ${state.idToken}`,
        },
      });
      // Refetch the list instead of a full reload
      refetch();
    } catch (err) {
      console.error('Delete error:', err);
      const errorMessage = err.response
        ? `Delete failed (${err.response.status}): ${
            err.response.data?.message || JSON.stringify(err.response.data)
          }`
        : `Delete failed: ${err.message}`;
      
      setEventErrors(prev => ({ ...prev, [eventID]: errorMessage }));
    }
  };

  if (!state.isAuthenticated) {
    navigate('/login?redirect=/events/my');
    return null;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-4xl mx-auto bg-white p-6 rounded shadow">
          <p>Loading your events...</p>
        </div>
      </div>
    );
  }

  if (error) {
    const errorMessage = error.response
      ? `Error (${error.response.status}): ${
          error.response.data?.message || JSON.stringify(error.response.data)
        }`
      : `Error: ${error.message}`;
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-4xl mx-auto bg-white p-6 rounded shadow">
          <ErrorMessage message={errorMessage} />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded shadow">
        <h1 className="text-3xl font-bold text-primary mb-4">My Events</h1>
        <p className="text-gray-700 mb-6">
          Here are the events you created. You can edit or delete them.
        </p>

        {(!myEvents || myEvents.length === 0) && (
          <p className="text-gray-600">No events found. You can create one!</p>
        )}

        {myEvents && myEvents.length > 0 && (
          <ul className="divide-y divide-gray-300">
            {myEvents.map((evt) => (
              <li key={evt.EventID} className="py-4">
                {eventErrors[evt.EventID] && (
                  <p className="text-red-600 mb-2">{eventErrors[evt.EventID]}</p>
                )}
                <div className="flex items-center justify-between">
                  <div>
                    <Link
                      to={`/events/${evt.EventID}`}
                      className="text-xl font-semibold text-primary hover:underline"
                    >
                      {evt.title}
                    </Link>
                    <p className="text-gray-600 text-sm">
                      <strong>Date:</strong> {evt.date || 'N/A'} |{' '}
                      <strong>Type:</strong> {evt.eventType || 'N/A'}
                      {evt.runningDistances && evt.runningDistances.length > 0 && (
                        <>
                          {' | '}
                          <strong>Running:</strong> {evt.runningDistances.join(', ')}
                        </>
                      )}
                      {evt.triathlonDistances && evt.triathlonDistances.length > 0 && (
                        <>
                          {' | '}
                          <strong>Triathlon:</strong> {evt.triathlonDistances.join(', ')}
                        </>
                      )}
                      {evt.customDistances && evt.customDistances.length > 0 && (
                        <>
                          {' | '}
                          <strong>Custom (km):</strong> {evt.customDistances.join(', ')}
                        </>
                      )}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <Link
                      to={`/events/edit/${evt.EventID}`}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600
                      border border-transparent rounded-md shadow-sm hover:bg-blue-700
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                      transition-colors"
                    >
                      Edit
                    </Link>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <button
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600
                          border border-transparent rounded-md shadow-sm hover:bg-red-700
                          focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2
                          transition-colors"
                        >
                          Delete
                        </button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Delete Event</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to delete this event? This action cannot be undone.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => handleDelete(evt.EventID)}
                          >
                            Confirm
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MyEvents;