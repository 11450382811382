import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import ErrorMessage from '../Message';
import { useAuth } from '../../context/AuthContext';
import EventInterestButton from './EventInterestButton';
import AttendeesList from './AttendeesList';

const PublicEventDetails = () => {
  const { eventId } = useParams();
  const { state } = useAuth();

  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    // fetch from the "public" endpoint (no auth required).
    const fetchPublicEvent = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/events/public/${eventId}`);
        setEventData(response.data);
      } catch (error) {
        console.error('Error fetching public event details:', error);
        if (error.response) {
          setErrorMsg(
            `Error (${error.response.status}): ${
              error.response.data?.message || JSON.stringify(error.response.data)
            }`
          );
        } else {
          setErrorMsg(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublicEvent();
  }, [eventId]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
          <p>Loading public event details...</p>
        </div>
      </div>
    );
  }

  if (errorMsg) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
          <ErrorMessage message={errorMsg} />
        </div>
      </div>
    );
  }

  if (!eventData) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
          <p className="text-red-500">No public event found or an error occurred.</p>
        </div>
      </div>
    );
  }

  const {
    title,
    date,
    location,
    description,
    eventType,
    country,
    website,
    standardDistances,
    customDistances,
  } = eventData;

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
        <h1 className="text-3xl font-bold text-primary mb-4">{title}</h1>

        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Date:</span> {date || 'N/A'}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Location:</span> {location || 'N/A'}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Event Type:</span> {eventType || 'N/A'}
        </p>

        {/* Standard Distances */}
        {standardDistances && standardDistances.length > 0 && (
          <p className="text-gray-700 mb-2">
            <span className="font-semibold">Standard Distances:</span> {standardDistances.join(', ')}
          </p>
        )}

        {/* Custom Distances */}
        {customDistances && customDistances.length > 0 && (
          <p className="text-gray-700 mb-2">
            <span className="font-semibold">Custom Distances (km):</span> {customDistances.join(', ')}
          </p>
        )}

        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Country:</span> {country || 'N/A'}
        </p>
        {website && (
          <p className="text-gray-700 mb-4">
            <span className="font-semibold">Website:</span>{' '}
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline"
            >
              {website}
            </a>
          </p>
        )}

        {/* Scrollable container for long descriptions */}
        <div className="max-h-96 overflow-y-auto mb-4 p-2 border rounded">
          <div
            className="prose max-w-none"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {/* If user is not authenticated, prompt them to log in for more details */}
        {!state.isAuthenticated && (
          <div className="mt-6 p-4 border border-gray-300 bg-gray-50 rounded">
            <h2 className="font-semibold text-lg text-gray-800">Want to see more details?</h2>
            <p className="mt-2 text-gray-700">
              Log in to mark your attendance, view who else is going, and connect with fellow participants!
            </p>
            <div className="mt-3">
              <Link
                to={`/login?redirect=/events/public/${eventId}`}
                className="inline-block bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark"
              >
                Log In
              </Link>
            </div>
          </div>
        )}

        {/* If the user is logged in, show interest button and attendee list */}
        {state.isAuthenticated && (
          <>
            <EventInterestButton eventId={eventId} />
            <AttendeesList eventId={eventId} />
          </>
        )}
      </div>
    </div>
  );
};

export default PublicEventDetails;