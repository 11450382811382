import React from 'react';
import { FaGooglePlay, FaApple } from 'react-icons/fa';

const MobileApps = () => {
  return (
    <div data-aos="fade-up" className="py-16 px-8 bg-gradient-to-r from-indigo-50 to-blue-50 text-center">
      <h2 className="text-4xl font-bold mb-8 text-gray-800">Get Our Mobile App</h2>
      <p className="text-gray-600 mb-6">
        Download the UltraRides app to enhance your cycling experience.
      </p>
      <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
        <a 
          href="https://play.google.com/store/apps/details?id=www.ultrarides.ultra_rides_app" 
          className="flex items-center justify-center bg-indigo-600 text-white py-3 px-8 rounded-full font-semibold text-xl hover:bg-indigo-700 transition-transform hover:scale-105"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGooglePlay className="mr-2" />
          Google Play
        </a>
        <a 
          href="https://apps.apple.com/in/app/ultrarides/id6612030068" 
          className="flex items-center justify-center bg-indigo-600 text-white py-3 px-8 rounded-full font-semibold text-xl hover:bg-indigo-700 transition-transform hover:scale-105"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaApple className="mr-2" />
          App Store
        </a>
      </div>
    </div>
  );
};

export default MobileApps;