import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from '../../config/axiosConfig';
import { format, isBefore } from 'date-fns';
import {
    FaUserCircle,
    FaSearch,
    FaUser,
    FaCalendarCheck,
    FaBicycle,
    FaBuilding,
    FaComments,
    FaDownload,
    FaCalendar,
    FaArrowRight
} from 'react-icons/fa';
import PDFDownloadButton from '../Profile/PDFDownloadButton';
import { useAuth } from '../../context/AuthContext';

import Badges from '../PublicProfile/Badges';
import TotalUltraRidesDistance from '../PublicProfile/TotalUltraRidesDistance';

const fetchMyRides = async () => {
    const response = await axios.get('/riders/rides');
    return response.data;
};

const fetchUserProfile = async (urn) => {
    const response = await axios.get(`/users/${urn}/profile`);
    return response.data;
};

// New function: fetch the user's club (returns null if the user does not have a club)
const fetchMyClub = async () => {
    try {
        const response = await axios.get('/clubs/myclub');
        return response.data; // If successful, user has a club
    } catch (error) {
        // If a 404 occurs, that means the user doesn't have a club
        if (error.response?.status === 404) {
            return null;
        }
        throw error; // Otherwise re-throw
    }
};

function Dashboard() {
    const navigate = useNavigate();
    const { state } = useAuth();
    const { user, idTokenPayload } = state;

    const urn = idTokenPayload?.['custom:userId'];
    const name = idTokenPayload?.name;

    const [searchUrn, setSearchUrn] = useState('');
    const [searchError, setSearchError] = useState('');

    // My Rides Query
    const {
        data: myRides,
        error: ridesError,
        isLoading: isRidesLoading
    } = useQuery({
        queryKey: ['myRides'],
        queryFn: fetchMyRides,
        enabled: state.isAuthenticated,
        staleTime: 1000 * 60 * 5,
    });

    // User Profile Query
    const {
        data: profileData,
        error: profileError,
        isLoading: isProfileLoading
    } = useQuery({
        queryKey: ['userProfile', urn],
        queryFn: () => fetchUserProfile(urn),
        enabled: !!urn,
        staleTime: 1000 * 60 * 5,
    });

    // Club Check Query
    const {
        data: myClub,
        error: myClubError,
        isLoading: isClubLoading
    } = useQuery({
        queryKey: ['myClub'],
        queryFn: fetchMyClub,
        enabled: state.isAuthenticated,
        staleTime: 1000 * 60 * 5,
    });

    const currentDate = new Date();
    const upcomingRides = myRides
        ?.filter(ride => isBefore(currentDate, new Date(ride.rideDetails.date)))
        .sort((a, b) => new Date(a.rideDetails.date) - new Date(b.rideDetails.date))
        .slice(0, 5) || [];

    const handleUrnSearch = (e) => {
        e.preventDefault();
        if (!searchUrn.trim()) {
            setSearchError('Please enter a URN');
            return;
        }
        const cleanedUrn = parseInt(searchUrn, 10).toString();
        setSearchError('');
        navigate(`/athletes/${cleanedUrn}/profile`);
    };

    const handleProfileClick = () => {
        navigate('/profile', { state: { name: user?.email, urn } });
    };

    const handlePublicProfileClick = () => {
        navigate(`/athletes/${urn}/profile`);
    };

    const handleRideClick = (rideId) => {
        navigate(`/rides/${rideId}`);
    };

    const buttonStyle =
        'w-full md:w-auto inline-flex items-center justify-center px-3 py-2 rounded text-sm border border-primary text-primary bg-surface hover:bg-primary hover:text-white transition duration-200';

    if (isRidesLoading || isProfileLoading || isClubLoading) {
        return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
    }

    if (ridesError) {
        console.error('Error fetching rides:', ridesError);
    }
    if (profileError) {
        console.error('Error fetching profile data:', profileError);
    }
    if (myClubError) {
        console.error('Error checking my club:', myClubError);
    }

    const displayName = profileData?.name || name || 'User';

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-background text-textPrimary">
            <aside className="w-full md:w-64 bg-surface shadow-md px-4 py-6 flex flex-col items-center">
                <div className="mb-4 flex flex-col items-center border-b pb-4 border-border">
                    {profileData?.ProfilePictureURL ? (
                        <img
                            src={profileData.ProfilePictureURL}
                            alt="Profile"
                            className="w-24 h-24 rounded-full object-cover mb-2"
                        />
                    ) : (
                        <FaUserCircle className="text-6xl text-primary mb-2" />
                    )}
                    <p className="text-lg font-semibold text-heading">{displayName}</p>
                    {urn && (
                        <p className="text-sm text-body mb-4">
                            URN: <span className="font-semibold">{urn}</span>
                        </p>
                    )}
                    <div className="flex flex-row md:flex-col gap-2 w-full">
                        <button onClick={handleProfileClick} className={buttonStyle}>
                            <FaUser className="mr-2" />
                            <span>Edit Profile</span>
                        </button>
                        <button onClick={handlePublicProfileClick} className={buttonStyle}>
                            <FaUserCircle className="mr-2" />
                            <span>Public Profile</span>
                        </button>
                    </div>
                </div>
            </aside>

            <main className="flex-1 p-6 overflow-y-auto">
                <div className="max-w-6xl mx-auto space-y-6">
                    <h1 className="text-3xl font-bold text-heading">Welcome to Your Dashboard</h1>

                    {upcomingRides.length > 0 && (
                        <div className="bg-surface shadow-sm rounded p-4">
                            <h2 className="text-lg font-semibold text-heading mb-4">Upcoming Rides</h2>
                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white border rounded-lg overflow-hidden">
                                    <thead className="bg-primary text-white">
                                        <tr>
                                            <th className="py-1 px-2 border-b-2 border-primary text-left">Ride Name</th>
                                            <th className="py-1 px-2 border-b-2 border-primary text-left">Date</th>
                                            <th className="py-1 px-2 border-b-2 border-primary text-left">Distance (km)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {upcomingRides.map((ride, index) => (
                                            <tr
                                                key={index}
                                                className="hover:bg-gray-100 cursor-pointer transition duration-150 ease-in-out"
                                                onClick={() => handleRideClick(ride.rideDetails.rideId)}
                                            >
                                                <td className="py-2 px-2 border-b border-gray-200 capitalize text-gray-700">
                                                    <div className="flex items-center justify-between">
                                                        <span>{ride.rideDetails.name}</span>
                                                        <FaArrowRight className="text-primary hover:text-secondary ml-2" />
                                                    </div>
                                                </td>
                                                <td className="py-2 px-2 border-b border-gray-200 text-gray-700">
                                                    {ride.rideDetails.date
                                                        ? format(new Date(ride.rideDetails.date), 'dd MMM yyyy')
                                                        : 'N/A'}
                                                </td>
                                                <td className="py-2 px-2 border-b border-gray-200 text-gray-700">
                                                    {ride.rideDetails.distance}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                        <div className="bg-surface shadow-sm rounded p-4 flex flex-col justify-between">
                            <div className="mb-4">
                                <h2 className="text-lg font-semibold text-heading">
                                    Rides &amp; Chats
                                </h2>
                                <p className="text-sm text-body mt-2">
                                    Access your rides, calendar, and chat with fellow riders.
                                </p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <button onClick={() => navigate('/myrides')} className={buttonStyle}>
                                    Go to My Rides
                                </button>
                                <button onClick={() => navigate('/rides')} className={buttonStyle}>
                                    Open Calendar
                                </button>
                                <button onClick={() => navigate('/mychats')} className={buttonStyle}>
                                    Open Ride Chats
                                </button>
                            </div>
                        </div>

                        {/* The "Your Club" or "Create a Club" column */}
                        <div className="bg-surface shadow-sm rounded p-4 flex flex-col justify-between">
                            <div className="mb-4">
                                <h2 className="text-lg font-semibold text-heading flex items-center gap-2">
                                    <FaBuilding className="text-primary" />
                                    {myClub ? 'Your Club' : 'Create a Club'}
                                </h2>
                                <p className="text-sm text-body mt-2">
                                    {myClub
                                        ? 'Manage your club and schedule events.'
                                        : 'Start your own club and schedule events.'
                                    }
                                </p>
                            </div>
                            <div className="flex flex-col gap-2">
                                {isClubLoading ? (
                                    <p>Loading club info...</p>
                                ) : myClub ? (
                                    <>
                                        <button onClick={() => navigate('/owner-dashboard')} className={buttonStyle}>
                                            Go to Owner Dashboard
                                        </button>
                                        <button
                                            onClick={() => navigate('/owner-dashboard/rides')}
                                            className={buttonStyle}
                                        >
                                            Manage Rides
                                        </button>
                                    </>
                                ) : (
                                    <button onClick={() => navigate('/clubs/new')} className={buttonStyle}>
                                        Create a Club
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="bg-surface shadow-sm rounded p-4 flex flex-col justify-between">
                            <div className="mb-4">
                                <h2 className="text-lg font-semibold text-heading flex items-center gap-2">
                                    <FaCalendarCheck className="text-primary" />
                                    Events
                                </h2>
                                <p className="text-sm text-body mt-2">
                                    Manage and join events.
                                </p>
                            </div>
                            <div className="flex flex-col gap-2">
                                <button onClick={() => navigate('/events/my')} className={buttonStyle}>
                                    View My Events
                                </button>
                                <button onClick={() => navigate('/events/new')} className={buttonStyle}>
                                    Create New Event
                                </button>
                            </div>
                        </div>
                    </div>

                    {profileData?.rideHistory && (
                        <div className="bg-surface shadow-sm rounded p-4 mt-6">
                            <h2 className="text-lg font-semibold text-heading mb-4">
                                My Achievements
                            </h2>
                            <div className="flex flex-col md:flex-row gap-4">
                                <div className="flex-1 bg-white p-4 rounded shadow">
                                    <TotalUltraRidesDistance rideHistory={profileData.rideHistory} />
                                </div>
                                <div className="flex-1 bg-white p-4 rounded shadow">
                                    <Badges rideHistory={profileData.rideHistory} />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                        <div className="bg-surface shadow-sm rounded p-4">
                            <h2 className="text-lg font-semibold text-heading mb-4">
                                Your Rider Card
                            </h2>
                            <p className="text-sm text-body mb-4">
                                Download your rider card to access your URN and rider information.
                            </p>
                            <PDFDownloadButton
                                leftSideData={{ customUserId: urn || '', name: displayName }}
                                idTokenPayload={idTokenPayload}
                            >
                                <div className={buttonStyle}>
                                    <FaDownload className="mr-2" />
                                    Download Rider Card
                                </div>
                            </PDFDownloadButton>
                        </div>

                        <div className="bg-surface shadow-sm rounded p-4">
                            <h2 className="text-lg font-semibold text-heading mb-4">
                                Find Athletes
                            </h2>
                            <form onSubmit={handleUrnSearch} className="space-y-4">
                                <div>
                                    <input
                                        type="text"
                                        className="w-full px-3 py-2 text-sm border rounded focus:outline-none focus:border-primary"
                                        placeholder="Enter URN"
                                        value={searchUrn}
                                        onChange={(e) => setSearchUrn(e.target.value)}
                                    />
                                    {searchError && <p className="text-danger text-xs mt-1">{searchError}</p>}
                                </div>
                                <button
                                    type="submit"
                                    className={`${buttonStyle} w-full`}
                                >
                                    <FaSearch className="mr-2" />
                                    <span>Search</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Dashboard;