import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';

const ForClubOwners = () => {
  return (
    <div
      data-aos="fade-up"
      className="flex flex-col md:flex-row items-center my-12 p-8 bg-gradient-to-br from-indigo-50 via-blue-50 to-white shadow-lg rounded-lg backdrop-blur-md transition-transform transform hover:scale-[1.02] mx-4 md:mx-8"
    >
      <div className="md:w-1/2 p-4">
        <h2 className="text-4xl font-extrabold mb-4 bg-gradient-to-r from-indigo-600 to-blue-600 bg-clip-text text-transparent">
          Empower Your Cycling Club
        </h2>
        <p className="text-gray-700 mb-6">
          UltraRides offers a robust platform to manage and promote your cycling events. Ensure your riders' achievements are permanently recognized with our homologation system. Enhance your club's visibility and provide a seamless experience for your members.
        </p>
        <ul className="mb-6 space-y-3">
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Homologation Management: Easily manage and track rider homologations.</span>
          </li>
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Digital Certificates: Issue digital certificates to riders upon completion of events.</span>
          </li>
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Public Profiles: Showcase your club's successful events and riders' achievements.</span>
          </li>
          <li className="flex items-center text-gray-700 bg-white/50 p-3 rounded-lg hover:bg-white/80 transition-colors">
            <FaRegCheckCircle className="text-indigo-600 mr-2 flex-shrink-0" />
            <span>Event Promotion: Utilize our platform to promote upcoming events.</span>
          </li>
        </ul>
        <a
          href="/create-account"
          className="inline-flex items-center px-8 py-3 rounded-full font-semibold text-xl text-white bg-gradient-to-r from-indigo-600 to-blue-600 hover:from-indigo-700 hover:to-blue-700 transform hover:-translate-y-1 transition-all shadow-lg hover:shadow-xl"
        >
          Get Started as a Club Owner
        </a>
      </div>
      <div className="md:w-1/2 p-4 mt-6 md:mt-0">
        <img
          src="/club.jpg"
          alt="Club Owner Managing Events"
          className="w-full rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
        />
      </div>
    </div>
  );
};

export default ForClubOwners;