import React, { useState, useEffect } from 'react';
import AdminLayout from '../AdminLayout';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import { AlertCircle, Clock, Save } from 'lucide-react';
import { format } from 'date-fns';

const StartFinishLineManager = () => {
    const { rideId } = useParams();
    const [riders, setRiders] = useState([]);
    const [startTimes, setStartTimes] = useState({});

    // Fetch ride details including registered riders
    const { data: rideData, isLoading } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
        staleTime: 1000 * 60 * 5,
    });

    // Calculate default start times based on sequence
    useEffect(() => {
        if (rideData?.registeredRiders) {
            const sortedRiders = [...rideData.registeredRiders].sort((a, b) => 
                (a.startSequence || Infinity) - (b.startSequence || Infinity)
            );
            setRiders(sortedRiders);

            // Initialize start times if not already set
            const baseStartTime = new Date(rideData.startDateTime);
            const newStartTimes = {};
            sortedRiders.forEach((rider, index) => {
                if (!startTimes[rider.userId]) {
                    const riderStartTime = new Date(baseStartTime);
                    riderStartTime.setSeconds(riderStartTime.getSeconds() + (index * 30)); // 30 seconds gap
                    newStartTimes[rider.userId] = format(riderStartTime, 'HH:mm:ss');
                }
            });
            setStartTimes(prev => ({ ...prev, ...newStartTimes }));
        }
    }, [rideData]);

    // Mutation for updating rider sequence and start time
    const updateRiderSequenceMutation = useMutation({
        mutationFn: ({ userId, sequence, startTime }) => 
            axios.post(`/rides/${rideId}/riders/${userId}/result`, {
                startSequence: sequence,
                plannedStartTime: startTime
            }),
        onSuccess: () => {
            // Could add toast notification here
            console.log('Sequence updated successfully');
        },
        onError: (error) => {
            console.error('Error updating sequence:', error);
        }
    });

    const handleSaveSequence = async (userId, sequence, startTime) => {
        try {
            await updateRiderSequenceMutation.mutateAsync({ 
                userId, 
                sequence,
                startTime 
            });
        } catch (error) {
            console.error('Error saving sequence:', error);
        }
    };

    const handleSequenceChange = (userId, newSequence) => {
        const updatedRiders = [...riders].map(rider => {
            if (rider.userId === userId) {
                return { ...rider, startSequence: parseInt(newSequence) || null };
            }
            return rider;
        });
        setRiders(updatedRiders.sort((a, b) => 
            (a.startSequence || Infinity) - (b.startSequence || Infinity)
        ));
    };

    const handleStartTimeChange = (userId, newTime) => {
        setStartTimes(prev => ({
            ...prev,
            [userId]: newTime
        }));
    };

    if (isLoading) {
        return (
            <AdminLayout title="Start/Finish Line Management">
                <div className="max-w-4xl mx-auto p-4">
                    <div>Loading...</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout title="Start/Finish Line Management">
            <div className="max-w-4xl mx-auto p-4">
                <div className="bg-white rounded-lg shadow overflow-hidden">
                    <div className="p-4 border-b">
                        <h2 className="text-lg font-semibold">Start Line Sequence</h2>
                        <p className="text-sm text-gray-600">Manage rider start sequence and timing</p>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        URN
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Rider Name
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Sequence
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Start Time
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {riders.map((rider) => (
                                    <tr key={rider.userId}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {rider.urn}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {rider.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <input
                                                type="number"
                                                className="w-20 px-2 py-1 border rounded"
                                                value={rider.startSequence || ''}
                                                onChange={(e) => handleSequenceChange(rider.userId, e.target.value)}
                                                min="1"
                                            />
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <input
                                                type="time"
                                                step="1"
                                                className="px-2 py-1 border rounded"
                                                value={startTimes[rider.userId] || ''}
                                                onChange={(e) => handleStartTimeChange(rider.userId, e.target.value)}
                                            />
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button
                                                className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => handleSaveSequence(
                                                    rider.userId, 
                                                    rider.startSequence,
                                                    startTimes[rider.userId]
                                                )}
                                            >
                                                <Save className="h-4 w-4 mr-1" />
                                                Save
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default StartFinishLineManager;
