import React, { useState } from 'react';
import ControlPointIcon from './ControlPointIcon';
import TimeEditor from './TimeEditor';
import { CheckCircle, XCircle, AlertTriangle, Clock, Timer, Edit2, AlertOctagon } from 'lucide-react';

const RiderRow = ({
   user,
   controlPoints,
   handleControlClick,
   handleStatusChange,
   handleClearResult,
   showToast
}) => {
   const [isEditingTime, setIsEditingTime] = useState(false);
   const [isFinishTimeEntry, setIsFinishTimeEntry] = useState(false);
   const safeControlPoints = controlPoints || [];
   const safeUserControlPoints = (user.ControlPoints || []).filter(cp => cp.ControlPointNumber > 0);

   const calculateProvisionalTime = (start, end) => {
       const startTime = new Date(start);
       const endTime = new Date(end);
       const duration = (endTime - startTime) / 1000;
       if (duration < 0) return null;
       const hours = Math.floor(duration / 3600);
       const minutes = Math.floor((duration % 3600) / 60);
       return `${hours}h ${minutes}m`;
   };

   const hasReachedLastControl = () => {
       if (!safeControlPoints.length || !safeUserControlPoints.length) return false;
       const lastControlNumber = safeControlPoints.length;
       return safeUserControlPoints.some(cp => parseInt(cp.ControlPointNumber) === lastControlNumber);
   };

   const startTime = safeControlPoints[0]?.closeTime;
   const lastCheckIn = hasReachedLastControl()
       ? safeUserControlPoints
           .filter(cp => parseInt(cp.ControlPointNumber) === safeControlPoints.length)
           .sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp))[0]?.Timestamp 
       : null;
   const provisionalTime = startTime && lastCheckIn ? calculateProvisionalTime(startTime, lastCheckIn) : '';

   const handleEditTime = (newTime) => {
       handleStatusChange(user.sub, user.Result || 'Finished', newTime);
       setIsEditingTime(false);
       setIsFinishTimeEntry(false);
       showToast('Time updated.');
   };

   const handleFinishWithTime = (time) => {
       handleStatusChange(user.sub, 'Finished', time);
       setIsFinishTimeEntry(false);
       showToast('Rider marked as finished with time.');
   };

   const TimeDisplay = ({ finalTime, provisionalTime }) => {
       if (isEditingTime) {
           return (
               <TimeEditor
                   initialTime={finalTime || ''}
                   onSave={handleEditTime}
                   onCancel={() => setIsEditingTime(false)}
               />
           );
       }

       if (finalTime) {
           const match = finalTime.match(/(\d+)h\s*(\d+)m/);
           if (match) {
               const [, hours, minutes] = match;
               if (parseInt(hours) >= 0 && parseInt(minutes) >= 0) {
                   return (
                       <div className="flex items-center text-gray-700 group text-sm">
                           <Clock className="h-4 w-4 mr-1" />
                           <span className="font-medium">{finalTime}</span>
                           <button
                               onClick={() => setIsEditingTime(true)}
                               className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity"
                               title="Edit Time"
                           >
                               <Edit2 className="h-4 w-4 text-blue-500 hover:text-blue-700" />
                           </button>
                       </div>
                   );
               }
           }
           return (
               <button
                   onClick={() => setIsEditingTime(true)}
                   className="flex items-center text-sm text-blue-600 hover:text-blue-800"
               >
                   <Clock className="h-4 w-4 mr-1" />
                   <span>Add time</span>
               </button>
           );
       }

       if (provisionalTime) {
           return (
               <div className="flex items-center text-gray-600 text-sm">
                   <Timer className="h-4 w-4 mr-1" />
                   <span className="italic">{provisionalTime}</span>
               </div>
           );
       }

       if (user.Result === 'Finished') {
           return (
               <button
                   onClick={() => setIsEditingTime(true)}
                   className="flex items-center text-sm text-blue-600 hover:text-blue-800"
               >
                   <Clock className="h-4 w-4 mr-1" />
                   <span>Add time</span>
               </button>
           );
       }

       return null;
   };

   const ActionButton = ({ onClick, icon: Icon, label, borderColor, textColor }) => (
       <button
           onClick={onClick}
           className={`inline-flex items-center gap-1 px-3 py-1 border rounded-md text-sm font-medium
                       ${borderColor} ${textColor} hover:bg-gray-50 transition-colors duration-150`}
       >
           <Icon className="h-4 w-4" />
           {label}
       </button>
   );

   const getQuickActionButtons = () => {
       if (isFinishTimeEntry) {
           return (
               <TimeEditor
                   initialTime=""
                   onSave={handleFinishWithTime}
                   onCancel={() => setIsFinishTimeEntry(false)}
               />
           );
       }

       if (user.Result) {
           return (
               <div className="flex items-center gap-3 bg-gray-50 p-2 rounded-md">
                   <div className="px-2 py-1 rounded-md text-sm font-medium text-gray-700">
                       {user.Result}
                   </div>
                   <TimeDisplay finalTime={user.TotalTime} provisionalTime={provisionalTime} />
                   <button
                       onClick={() => handleClearResult(user.sub)}
                       className="p-1 text-gray-500 hover:text-red-600 rounded-md hover:bg-gray-100 transition-colors duration-150 ease-in-out"
                       title="Clear Result"
                   >
                       <XCircle className="h-5 w-5" />
                   </button>
               </div>
           );
       }

       return (
           <div className="flex flex-wrap gap-2">
               <ActionButton
                   onClick={() => {
                       if (!provisionalTime) {
                           setIsFinishTimeEntry(true);
                       } else {
                           handleStatusChange(user.sub, 'Finished', provisionalTime);
                       }
                   }}
                   icon={CheckCircle}
                   label="Finish"
                   borderColor="border-green-500"
                   textColor="text-green-500"
               />
               <ActionButton
                   onClick={() => handleStatusChange(user.sub, 'Late Finish', provisionalTime)}
                   icon={AlertOctagon}
                   label="Late"
                   borderColor="border-orange-500"
                   textColor="text-orange-500"
               />
               <ActionButton
                   onClick={() => handleStatusChange(user.sub, 'DNF')}
                   icon={XCircle}
                   label="DNF"
                   borderColor="border-red-500"
                   textColor="text-red-500"
               />
               <ActionButton
                   onClick={() => handleStatusChange(user.sub, 'DNS')}
                   icon={AlertTriangle}
                   label="DNS"
                   borderColor="border-gray-500"
                   textColor="text-gray-500"
               />
               {provisionalTime && (
                   <div className="flex items-center gap-2 bg-gray-50 px-2 py-1 rounded-md text-sm">
                       <TimeDisplay provisionalTime={provisionalTime} />
                   </div>
               )}
           </div>
       );
   };

   const calculateDistance = (lat1, lon1, lat2, lon2) => {
       if (!lat1 || !lon1 || !lat2 || !lon2) return null;
       const R = 6371e3;
       const φ1 = lat1 * Math.PI/180;
       const φ2 = lat2 * Math.PI/180;
       const Δφ = (lat2-lat1) * Math.PI/180;
       const Δλ = (lon2-lon1) * Math.PI/180;
       const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
               Math.cos(φ1) * Math.cos(φ2) *
               Math.sin(Δλ/2) * Math.sin(Δλ/2);
       const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
       return R * c;
   };

   return (
       <tr className="hover:bg-gray-50 transition-colors text-sm">
           <td className="px-4 py-2 whitespace-nowrap text-gray-900">
               {user.URN}
           </td>
           <td className="px-4 py-2 whitespace-nowrap text-gray-900">
               {user.Name}
           </td>
           <td className="px-4 py-2">
               <div className="flex items-center space-x-2">
                   {safeControlPoints.map((point, idx) => {
                       const checkedIn = safeUserControlPoints.find(cp => cp.ControlPointNumber == idx + 1);
                       const expectedCoordinates = point.gpsCoordinates
                           ? point.gpsCoordinates.split(',').map(coord => parseFloat(coord.trim()))
                           : [];
                       const distance = checkedIn ? calculateDistance(expectedCoordinates[0],
                           expectedCoordinates[1],
                           checkedIn.Latitude,
                           checkedIn.Longitude
                       ) : null;

                       return (
                           <div
                               key={idx}
                               className="cursor-pointer"
                               onClick={() => handleControlClick(checkedIn || null, point, user, idx)}
                               title={`CP${idx + 1}`}
                           >
                               <ControlPointIcon 
                                   checkedIn={checkedIn} 
                                   distance={distance} 
                                   closeTime={point.closeTime}
                               />
                           </div>
                       );
                   })}
               </div>
           </td>
           <td className="px-4 py-2">
               {getQuickActionButtons()}
           </td>
       </tr>
   );
};

export default RiderRow;