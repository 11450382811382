import React, { useState } from 'react';
import { Clock, Check, X } from 'lucide-react';

const TimeEditor = ({ initialTime, onSave, onCancel }) => {
   const [hours, setHours] = useState(initialTime ? parseInt(initialTime.split('h')[0]) : 0);
   const [minutes, setMinutes] = useState(initialTime ? parseInt(initialTime.split('h')[1].split('m')[0]) : 0);

   const handleSave = () => {
       const formattedTime = `${hours}h ${minutes}m`;
       onSave(formattedTime);
   };

   return (
       <div className="flex items-center gap-2">
           <Clock className="h-4 w-4 text-gray-500" />
           <input
               type="number"
               min="0"
               value={hours}
               onChange={(e) => setHours(parseInt(e.target.value) || 0)}
               className="w-16 px-2 py-1 border rounded"
               placeholder="Hours"
           />
           <span>h</span>
           <input
               type="number"
               min="0"
               max="59"
               value={minutes}
               onChange={(e) => setMinutes(parseInt(e.target.value) || 0)}
               className="w-16 px-2 py-1 border rounded"
               placeholder="Minutes"
           />
           <span>m</span>
           <button
               onClick={handleSave}
               className="p-1 text-green-600 hover:text-green-800 rounded-full hover:bg-green-50"
               title="Save"
           >
               <Check className="h-4 w-4" />
           </button>
           <button
               onClick={onCancel}
               className="p-1 text-red-600 hover:text-red-800 rounded-full hover:bg-red-50"
               title="Cancel"
           >
               <X className="h-4 w-4" />
           </button>
       </div>
   );
};

export default TimeEditor;
